import './mobileDashboardGraphs.css'


const MobileDashboardGraphs = () => {

    return (
        <div className='WorkInProgress__Container'>
            <div className='WorkInProgress__Message'>
                <div className='WorkInProgress__Title'>Work in Progress</div>
                <div className='WorkInProgress__Description'>
                    This page is currently under construction. We’re working hard to bring you something great!🚧
                </div>
            </div>
        </div>
    );

}


export default MobileDashboardGraphs;