import { useEffect, useState } from 'react';
import './homePage.css';
import HomePageNews from './HomePage-Components/homePageNews';
import HomePageSectoren from './HomePage-Components/homePageSectoren';
import HomePageOurSolutions from './HomePage-Components/homePageOurSolutions';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from 'react-i18next';

const HomePage = () => {
    const { i18n } = useTranslation();
    const { loginWithRedirect, isAuthenticated } = useAuth0();
    const location = useLocation();
    const [videoAvailable, setVideoAvailable] = useState(true);

    useEffect(() => {
        if (window.matchMedia('(display-mode: standalone)').matches) {
            loginWithRedirect({
                appState: { returnTo: '/HomePage' },
            });
        }
    }, [location, isAuthenticated, loginWithRedirect]);

    const videoUrls = {
        nl: "https://www.youtube.com/embed/igHvEIG63SE",
        en: "https://www.youtube.com/embed/bMtm26yeiP4"
    };

    const currentLanguage = i18n.language.startsWith('en') ? 'en' : 'nl';
    const videoId = videoUrls[currentLanguage].split('/').pop();

    useEffect(() => {
        const checkVideoAvailability = async () => {
            try {
                const response = await fetch(`https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${videoId}&format=json`);
                if (!response.ok) {
                    throw new Error('Video not available');
                }
                setVideoAvailable(true);
            } catch (error) {
                setVideoAvailable(false);
            }
        };

        checkVideoAvailability();
    }, [videoId]);

    return (
        <div className='homepagecontainer'>
            <HomePageNews />

            {videoAvailable && (
                <div className="youtube-video-container" style={{ width: '100%' }}>
                    <iframe
                        style={{ width: '100%', height: '56vw' }}
                        src={`${videoUrls[currentLanguage]}?autoplay=1&loop=1&mute=1&playlist=${videoId}`}
                        title="YouTube Video"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
            )}

            <HomePageOurSolutions />
            <HomePageSectoren />
        </div>
    );
};

export default HomePage;
