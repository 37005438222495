import './graphContainer.css'
import GraphSelectorItem from './GraphSelector/graphSelectorItem'
import { useContext, useEffect, useRef, useState } from "react";
import GraphMaker from './GraphMaker/graphMaker';
import { Bar, Line } from 'recharts'

import { DarkModeContext } from '../../../../context/DarkModeContext';
import { DndContext, DragOverlay } from '@dnd-kit/core';
import {Droppable} from '../../../DragAndDrop/Droppable';
import {Draggable} from '../../../DragAndDrop/Draggable';
import GraphValues from '../../../../api/graphValues';

import questionMarkBlack from '../../../../Images/questionMarkBlack.png';
import questionMark from '../../../../Images/questionIcon.png';

import Policy from '../../../../api/policy';

import { format, isToday, parse, subDays, addDays, subMonths, addMonths, isThisMonth, startOfMonth, endOfMonth, startOfWeek, endOfWeek, subWeeks, addWeeks, isThisWeek, getDaysInMonth } from 'date-fns';

const GraphContainer = ({serialNr}) => {

    const [date, setDate] = useState(format(new Date(), 'dd-MM-yyyy'))
    const [isInfoHovered, setIsInfoHovered] = useState(false);
    const {darkMode} = useContext(DarkModeContext);
    const [rememberIds, setRememberIds] = useState([])
    const [showRetryMessage, setShowRetryMessage] = useState(false);
    const [retryCall, setRetryCall] = useState(false);

    const [setpowerblockuser, setSetPowerblockUser] = useState(false);

    const [loader, setLoader] = useState(false);
    const [viewMode, setViewMode] = useState('day')

    const policyApi = Policy()
    const graphValueApi = GraphValues();


    // Initial graph data configuration
    const initialData = [
        { id: 1, name: "production", color: "#DABB50", chartObject: <Bar key={1} unit={"kWh"} dataKey={'production'} fill='#DABB50' radius={[5, 5, 0, 0]}/>, requestName: "pv_power", data: [] },
        { id: 2, name: "consumption", color: "#CE80EA", chartObject: <Bar key={2} unit={"kWh"}  dataKey={'consumption'} fill='#CE80EA' radius={[5, 5, 0, 0]}/>, requestName: "consumer_power", data: [] },
        { id: 3, name: "export to grid", color: "#F88C8C", chartObject: <Bar key={3} unit={"kWh"}  dataKey={'export to grid'} fill='#F88C8C' radius={[5, 5, 0, 0]}/>, requestName: "grid_export_power", data: [] },
        { id: 4, name: "import from grid", color: "#46B6BD", chartObject: <Bar key={4} unit={"kWh"}  dataKey={'import from grid'} fill='#46B6BD' radius={[5, 5, 0, 0]}/>, requestName: "grid_import_power", data: [] },
        { id: 5, name: "soc", color: "#65D8A0", chartObject: <Line key={5} strokeWidth={3} unit={"%"}  dot={false} yAxisId={'Percentage'} dataKey={'soc'} stroke='#65D8A0'/>, requestName: "battery_soc", data: [] },
    ];

    // State to hold the list of items (graphs)
    const [items, setItems] = useState(initialData)

    //fetches data whenever serialNr or date changes
    useEffect(() => {
        console.log(date)
        if (serialNr) {
            const requestGraphValueData = async () => {
                let attempts = 0;
                setLoader(true);
                setShowRetryMessage(false);

                const fetchData = async() => {
                    try {
                        const parsedDate = parse(date, 'dd-MM-yyyy', new Date());
                        let interval, dataPointLimit, start_date, end_date;

                        if (viewMode === 'day') {
                            interval = '1h';
                            dataPointLimit = 24;
                            start_date = parsedDate;
                            end_date = addDays(start_date, 1);
                        } else if (viewMode === 'week') {
                            interval = '1d';
                            dataPointLimit = 7;
                            start_date = startOfWeek(parsedDate, { weekStartsOn: 1 });
                            end_date = addDays(start_date, 7);
                        } else {
                            interval = '1d';
                            dataPointLimit = getDaysInMonth(parsedDate);
                            start_date = startOfMonth(parsedDate); // Start of the month
                            end_date = addDays(endOfMonth(parsedDate), 1);
                        }

                        const data = await graphValueApi.getGraphData(serialNr, start_date, end_date, interval);
                        console.log("Data: ", data)

                        setItems((prevItems) =>
                            prevItems.map((item) => {
                                const newData = [];
            
                                if (data[`${item.requestName}`] && data[`${item.requestName}`].time) {
                                    for (let i = 0; i < data[`${item.requestName}`].time.length && i < dataPointLimit; i++) {
                                        if (item.requestName == "battery_soc") {
                                            newData.push({
                                                date: new Date(data[`${item.requestName}`].time[i]),
                                                value: Math.abs(data[`${item.requestName}`].values[i]) * 100,
                                            });
                                        } else {
                                            newData.push({
                                                date: new Date(data[`${item.requestName}`].time[i]),
                                                value: Math.abs(data[`${item.requestName}`].values[i]),
                                            });
                                        }
                                    }
                                    console.log(newData)
                                    return { ...item, data: newData };
                                } else {
                                    return { ...item, data: [] };
                                }
                            })
                        );
                        setLoader(false);
                    } catch (error) {
                        console.error(error.message);
                        if (error.message == "interval 1 day not yet supported for installation") {
                            setSetPowerblockUser(true)
                        }
                        
                        if (attempts < 4) {
                            attempts++;
                            console.error("Fetching graph data failed retrying in 5s... attempt:", attempts)
                            setTimeout(fetchData, 5000)
                        } else {
                            setLoader(false);
                            setShowRetryMessage(true);
                        }
                    }
                }

                await fetchData();
            };

            requestGraphValueData();
        }
    }, [serialNr, date, retryCall, viewMode]);

    useEffect(() => {
        if (rememberIds.length > 0 && items[items.length - 1].data && items[items.length - 1].data.length > 0) {

            rememberItemsFromBefore()
        }
    }, [items])

    // State for time-based data structure
    const [data, setData] = useState([]);

    // State to hold the list of chart objects and selected items
    const [chartObjects, setChartObjects] = useState([])
    const [selectedItems, setSelectedItems] = useState([]);
    const [activeItem, setActiveItem] = useState(null); //holds the currently dragged item

    const rememberItemsFromBefore = () => {

        handleItemListAdded()
        setRememberIds([])
    }

    function handleMouseEnter() {
        setIsInfoHovered(true)
    }

    function handleMouseLeave() {
        setIsInfoHovered(false)
    }

    function handleDragStart(event) {
        setActiveItem(items.find((element) => element.id === event.active.id));
    }

    // When dragging ends, handle dropping into the graph
    function handleDragEnd(event) { 

        if (event.over && event.over.id == "droppable") {

            const newItem = items.find((element) => element.id === event.active.id);
           
            handleSelectedItemAdded(newItem)
        }

        setActiveItem(null)
    }

    const handleItemListAdded = () => {
        let newData = []
        for (let i = 0; i < rememberIds.length; i++) {

            
            const newItem = items.find((element) => element.id === rememberIds[i]);
            const newItemData = newItem.data
            
            if (newItemData.length !== 0) {
                // let newData = []
                if (!newData || newData.length < 1) {
                    newData = newItemData.map((item) => {
                        return {
                            date: item.date,
                            [newItem.name]: item.value
                        }
                    })
                } else {
                    newData = newData.map((entry, i) => ({
                        ...entry,
                        [newItem.name] : newItemData[i] == undefined || newItemData[i].value == null  ? null : newItemData[i].value
                    }))
                }
    
                const newChartObject = {'id': newItem.id, 'object': newItem.chartObject}
                setChartObjects((prevChartObjects) => [
                    ...prevChartObjects,
                    newChartObject
                ])
    
                setSelectedItems((prevSelectedItems) => [
                    ...prevSelectedItems,
                    newItem
                ]);
    
                //remove the item from the available list
                setItems((prevItems) => 
                    prevItems.filter((item) => item.id !== newItem.id)
                );
            }
        }

        const formatData = formatDataForChart(newData)
        setData(formatData)
        
    }

    const handleSelectedItemAdded = (newItem) => {

        const newItemData = newItem.data

        if (newItemData.length !== 0) {
            let newData = []
            if (!data || data.length < 1) {
                newData = newItemData.map((item) => {
                    return {
                        date: item.date,
                        [newItem.name]: item.value
                    }
                })
            } else {
                newData = data.map((entry, i) => ({
                    ...entry,
                    [newItem.name] : newItemData[i] == undefined || newItemData[i].value == null  ? null : newItemData[i].value
                }))
            }

            const formatData = formatDataForChart(newData)

            setData(formatData)

            const newChartObject = {'id': newItem.id, 'object': newItem.chartObject}
            setChartObjects((prevChartObjects) => [
                ...prevChartObjects,
                newChartObject
            ])

            setSelectedItems((prevSelectedItems) => [
                ...prevSelectedItems,
                newItem
            ]);

            //remove the item from the available list
            setItems((prevItems) => 
                prevItems.filter((item) => item.id !== newItem.id)
            );
        }
    }

    const handleSelectedItemRemove = (name) => {

        const foundItem = selectedItems.find((element) => element.name == name);

        setItems((prevItems) => {
            const updatedItems = [...prevItems, foundItem];
            return updatedItems.sort((a, b) => a.id - b.id);
        })
        setSelectedItems((prevSelectedItems) => 
            prevSelectedItems.filter((item) => item.id !== foundItem.id)
        );
        setChartObjects((prevChartObjects) =>
            prevChartObjects.filter((item) => item.id !== foundItem.id)
        );
    }
    
    
    const handleDateBackClick = () => {
        setItems(initialData)

        const ids = selectedItems.map((item) => item.id)
        setRememberIds(ids)

        setChartObjects([])
        setSelectedItems([])
        setData([])

        const parsedDate = parse(date, 'dd-MM-yyyy', new Date());

        if (viewMode === 'day') {
            const dateBefore = subDays(parsedDate, 1);
            setDate(format(dateBefore, 'dd-MM-yyyy'));
        } else if (viewMode === 'week') {
            const weekBefore = subWeeks(parsedDate, 1);
            setDate(format(weekBefore, 'dd-MM-yyyy'));
        } else {
            const monthBefore = subMonths(parsedDate, 1);
            setDate(format(monthBefore, 'dd-MM-yyyy'));
        }
    }

    const handleDateForwardClick = () => {
        const parsedDate = parse(date, 'dd-MM-yyyy', new Date());
        const today = new Date();

        if (viewMode === 'day' && !isToday(parsedDate) && parsedDate < today) {
            setItems(initialData)
            const ids = selectedItems.map((item) => item.id)
            setRememberIds(ids)
            setChartObjects([])
            setSelectedItems([])
            setData([])
            const dateAfter = addDays(parsedDate, 1);

            if (dateAfter > today) {
                setDate(format(today, 'dd-MM-yyyy'));
            } else {
                setDate(format(dateAfter, 'dd-MM-yyyy'));
            }
        } else if (viewMode === 'week' && !isThisWeek(parsedDate) && parsedDate < today) {
            setItems(initialData)
            const ids = selectedItems.map((item) => item.id)
            setRememberIds(ids)
            setChartObjects([])
            setSelectedItems([])
            setData([])
            const weekAfter = addWeeks(parsedDate, 1);

            const endOfCurrentWeek = endOfWeek(today, { weekStartsOn: 1 });
            if (weekAfter > endOfCurrentWeek) {
                setDate(format(endOfCurrentWeek, 'dd-MM-yyyy'));
            } else {
                setDate(format(weekAfter, 'dd-MM-yyyy'));
            }
        } else if (viewMode === 'month' && !isThisMonth(parsedDate) && parsedDate < today) {
            setItems(initialData)
            const ids = selectedItems.map((item) => item.id)
            setRememberIds(ids)
            setChartObjects([])
            setSelectedItems([])
            setData([])
            const monthAfter = addMonths(parsedDate, 1);

            const endOfCurrentMonth = endOfMonth(today);
            if (monthAfter > endOfCurrentMonth) {
                setDate(format(endOfCurrentMonth, 'dd-MM-yyyy'));
            } else {
                setDate(format(monthAfter, 'dd-MM-yyyy'));
            }
        }
    };

    const formatDataForChart = (data) => {
        return data.map(item => ({
            ...item,
            timestamp: new Date(item.date).getTime()  // Convert Date object to timestamp
        }));
    };

    const handleViewModeChange = (mode) => {
        if (mode !== viewMode) {
            setViewMode(mode)
            setItems(initialData);

            setRememberIds([]);
            setChartObjects([]);
            setSelectedItems([]);
            setData([]);
        }
    };

    const getDisplayDate = () => {
        const parsedDate = parse(date, 'dd-MM-yyyy', new Date());
        if (viewMode === 'day') {
            return format(parsedDate, 'dd-MM-yyyy');
        } else if (viewMode === 'week') {
            // const weekStart = startOfWeek(parsedDate, { weekStartsOn: 1 });
            // const weekEnd = endOfWeek(parsedDate, { weekStartsOn: 1 });
            // return `${format(weekStart, 'dd-MM')} - ${format(weekEnd, 'dd-MM-yyyy')}`;
            return "Week " + format(parsedDate, 'w');
        } else {
            return format(parsedDate, 'MMMM yyyy');
        }
    };

    const isForwardArrowVisible = () => {
        const parsedDate = parse(date, 'dd-MM-yyyy', new Date());
        const today = new Date();

        if (viewMode === 'day') {
            return !isToday(parsedDate) && parsedDate < today;
        } else if (viewMode === 'week') {
            return !isThisWeek(parsedDate) && parsedDate < today;
        } else {
            return !isThisMonth(parsedDate) && parsedDate < today;
        }
    };


    const handleRetry = () => {
        setShowRetryMessage(false);
        setRetryCall(!retryCall);
    };

    return (
        <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
            <div className='GraphContainer'>
                <div className={`GraphSelector ${activeItem ? 'no-scroll' : ''} ${isInfoHovered ? `${darkMode? 'infoHoveredDarkmode' : 'infoHovered'}`: ''}`} style={{border: darkMode ?  '#3a3a3a solid 2px' : '#CBCBCB solid 2px'}}>
                    {items.map(item => 
                        <Draggable key={item.id} id={item.id}>
                            <GraphSelectorItem 
                                Name={item.name} 
                                Color={item.color} 
                                Data={item.data} 
                                GradientId={item.id}
                                Loader={loader}
                            />
                        </Draggable>
                    )}
                </div>

                <DragOverlay>
                    {activeItem ? (
                        <GraphSelectorItem 
                            Name={activeItem.name} 
                            Color={activeItem.color} 
                            Data={activeItem.data} 
                            GradientId={activeItem.id}
                    />
                    ) : null}
                </DragOverlay>

                <div className='GraphDisplay' style={{border: darkMode ?  '#3a3a3a solid 2px' : '#CBCBCB solid 2px', position: 'relative'}}>
                    <div className='GraphDisplay-Date-Container'>
                    {setpowerblockuser && <div style={{fontSize: '0.8rem', fontWeight: 'light'}}>Week & Month view are still in development</div>}
                    <div style={darkMode ? { backgroundColor: '#353535', border: '2px solid #606060' } : { backgroundColor: '#D0D0D0', border: '2px solid #A0A0A0' }} className='GraphDisplay-Date-Category-Container'>
                            <div
                                className={`GraphDisplay-Date-Category-Button ${viewMode === 'day' ? 'active' : ''}`}
                                onClick={() => handleViewModeChange('day')}
                                style={viewMode === 'day' ?
                                    (darkMode ? { backgroundColor: '#505050' } : { backgroundColor: '#B0B0B0' })
                                    : {}}
                            >
                                Day
                            </div>
                            {/* <div
                                className={`GraphDisplay-Date-Category-Button ${viewMode === 'week' ? 'active' : ''}`}
                                onClick={() => handleViewModeChange('week')}
                                style={viewMode === 'week' ?
                                    (darkMode ? { backgroundColor: '#505050' } : { backgroundColor: '#B0B0B0' })
                                    : {}}
                            >
                                Week
                            </div> */}
                            {/* <div
                                className={`GraphDisplay-Date-Category-Button ${viewMode === 'month' ? 'active' : ''}`}
                                onClick={() => handleViewModeChange('month')}
                                style={viewMode === 'month' ?
                                    (darkMode ? { backgroundColor: '#505050' } : { backgroundColor: '#B0B0B0' })
                                    : {}}
                            >
                                Month
                            </div> */}
                        </div>
                        <div style={darkMode ? { backgroundColor: '#353535', border: '2px solid #606060' } : { backgroundColor: '#D0D0D0', border: '2px solid #A0A0A0' }} className='GraphDisplay-Date-Date-Container'>
                            <div className='GraphDisplay-Date-Changer' onClick={handleDateBackClick}>
                                <div style={darkMode ? { backgroundColor: '#D9D9D9' } : { backgroundColor: '#808080' }} className='GraphDisplay-Date-Changer-Bar Top Left'></div>
                                <div style={darkMode ? { backgroundColor: '#D9D9D9' } : { backgroundColor: '#808080' }} className='GraphDisplay-Date-Changer-Bar Bottom Left'></div>
                            </div>
                            <div className='GraphDisplay-Date-Date'>{getDisplayDate()}</div>
                            <div className='GraphDisplay-Date-Changer' style={{ visibility: !isForwardArrowVisible() ? 'hidden' : 'visible' }} onClick={handleDateForwardClick}>
                                <div style={darkMode ? { backgroundColor: '#D9D9D9' } : { backgroundColor: '#808080' }} className='GraphDisplay-Date-Changer-Bar Top Right'></div>
                                <div style={darkMode ? { backgroundColor: '#D9D9D9' } : { backgroundColor: '#808080' }} className='GraphDisplay-Date-Changer-Bar Bottom Right'></div>
                            </div>
                        </div>
                    </div>
                    <Droppable>
                        <GraphMaker handleRetry={handleRetry} showRetryMessage={showRetryMessage} date={parse(date, 'dd-MM-yyyy', new Date())} handleSelectedItemRemove={handleSelectedItemRemove} chartObjects={chartObjects} data={data} viewMode={viewMode}></GraphMaker>
                    </Droppable>
                    <div className='GraphInfo' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <img width={25} src={darkMode ? questionMark : questionMarkBlack} alt="" />
                        <div className={darkMode ? 'PopupText' : 'PopupTextBlack'}>Drag and drop your desired data into the graph and compare.</div>
                    </div>
                </div>
            </div>
        </DndContext>
    );
}

export default GraphContainer