import MobileFooterNavbar from '../Mobile-Footer-Navbar/mobileFooterNavbar';
import MobileDashboardSlider from './Mobile-Graph-Slider/mobileGraphSlider';
import MobileDashboardCurrentFlow from './Mobile-Dashboard-CurrentFlow/mobiledashboardcurrentflow';
import './mobileDashboardOverview.css'
import { useEffect, useMemo, useState } from 'react';
import useFlowData from '../../Dashboard-Hooks/useFlowData';

const MobileDashboardOverview = ({serial_number, username = ''}) => {

    const [retryKey, setRetryKey] = useState(0);

    const flowData = useFlowData(serial_number, retryKey);

    const retryFlowData = () => {
        setRetryKey(prevKey => prevKey + 1); // Increment key to force re-run
    };

    const getGreeting = () => {
        const hour = new Date().getHours();
        if (hour < 12) {
            return "Good morning";
        } else if (hour < 18) {
            return "Good afternoon";
        } else {
            return "Good evening";
        }
    };
    
    const memoizedFlowData = useMemo(() => {
        if (!flowData) return null;
        if (flowData === "Error") return "Error";
        return {
            SolarW: flowData.SolarW,
            GridW: flowData.GridW,
            BatteryW: flowData.BatteryW,
            HomeW: flowData.HomeW,
            BatteryP: flowData.BatteryP,
        };
    }, [flowData]);

    return (
        <div className='Dashboard__Mobile__Overview__Container'>
            <header className='Dashboard__Mobile__Overview__Navbar'>
                <div>
                    <div style={{fontSize: '2rem', marginBottom: 5}}>Dashboard</div>
                    <div style={{fontSize: '1.25rem'}}><span>{getGreeting()}</span> <span style={{color: "#41B1F0"}}>{username}!</span></div>
                </div>
                <div></div>
            </header>
            <div className='Mobile__Dashboard__Body__Container'>
                <div className='Mobile__Dashboard__Slider__Container'>
                    <MobileDashboardSlider serial_number={serial_number}/>
                </div>
                <div className='Mobile__Dashboard__Currentflow__Container'>
                    <MobileDashboardCurrentFlow data={memoizedFlowData} retryFlowData={retryFlowData}/>
                </div>
            </div>
        </div>
    )
}

export default MobileDashboardOverview;