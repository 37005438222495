import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import './eBoiler.css';

import eboilerMain from '../../../../Images/eboiler.png';
import eboilerSerie from '../../../../Images/eboilerSerie.jpg';
import eboilerComponents from '../../../../Images/eboiler2.png';
import eboilerVideo from '../../../../Images/eboiler.mp4';
import banner from "../../../../Images/Banner+datum-Amsterdam-NL-750x150px.jpg";

const EBoiler = () => {
    const { t } = useTranslation();

    return (
        <div className="eboilerPage__container">
            <img alt="banner" src={banner} className="eboiler__banner" />

            <section className="eboiler__event">
                <h2 className="eboiler__section-title">{t('event.title')}</h2>
                <p>
                    <Trans i18nKey="event.participation">
                        FIRN Energy neemt deel aan <strong>Solar Solutions Amsterdam</strong>,
                        de vakbeurs voor hernieuwbare energie in Nederland.
                    </Trans>
                </p>
                <p>{t('event.industry')}</p>
                <p>
                    <Trans i18nKey="event.invitation">
                        Kom langs op onze stand <strong>M13</strong> van <strong>11 t.e.m. 13 maart 2025</strong> en ontdek hoe e-boilers
                        jouw energieverbruik kunnen optimaliseren.
                    </Trans>
                </p>
                <a
                    href="https://www.solarsolutions.nl/partner/firnsolar/"
                    className="eboiler__button"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {t('event.register')}
                </a>
            </section>

            <section className="eboilerPage__introduction">
                <img src={eboilerMain} alt="E-Boiler componenten" className="eboilerPage__main" />
                <div className="eboilerPage__header">
                    <h1>{t('eboiler.eboilerTitle')}</h1>
                    <div className="eboilerPage__intro-text">
                        <p>{t('eboiler.eboilerDescription')}</p>
                    </div>
                </div>
            </section>

            <section>
                <div className="eboilerPage__main-content">
                    <div className="eboilerPage__main-text">
                        <h2>{t('eboiler.mainTitle')}</h2>
                        <p>{t('eboiler.mainDescription1')}</p>
                        <p>{t('eboiler.mainDescription2')}</p>
                        <h3>{t('eboiler.whyChoose')}</h3>
                        <ul>
                            <li><strong>{t('eboiler.efficiency')}:</strong> {t('eboiler.efficiencyDescription')}</li>
                            <li><strong>{t('eboiler.sustainability')}:</strong> {t('eboiler.sustainabilityDescription')}</li>
                            <li><strong>{t('eboiler.smartControl')}:</strong> {t('eboiler.smartControlDescription')}</li>
                            <li><strong>{t('eboiler.integration')}:</strong> {t('eboiler.integrationDescription')}</li>
                        </ul>
                    </div>
                    <div className="eboilerPage__components">
                        <img src={eboilerComponents} alt="E-Boiler" />
                    </div>
                </div>
            </section>

            <div className="eboilerPage__installation-and-hybrid">
                <section className="eboilerPage__installed">
                    <h2>{t('eboiler.installation')}</h2>
                    <div className="eboilerPage__installed-content">
                        <img src={eboilerSerie} alt="E-Boiler in serie" className="eboiler-serie-image" />
                        <p>{t('eboiler.installationDescription')}</p>
                    </div>
                </section>

                <div className="eboilerPage__video-hybrid-container">
                    <section className="eboilerPage__hybrid">
                        <h2>{t('eboiler.hybrid')}</h2>
                        <div className="eboilerPage__hybrid-content">
                            <p>{t('eboiler.hybridDescription')}</p>
                            <ul>
                                <li>{t('eboiler.hybridFeature1')}</li>
                                <li>{t('eboiler.hybridFeature2')}</li>
                                <li>{t('eboiler.hybridFeature3')}</li>
                            </ul>
                            <p>{t('eboiler.hybridConclusion')}</p>
                        </div>
                    </section>

                    <section className="eboilerPage__video">
                        <video autoPlay loop muted>
                            <source src={eboilerVideo} type="video/mp4" />
                            {t('container.containerError')}
                        </video>
                    </section>
                </div>
            </div>
        </div>
    );
}

export default EBoiler;