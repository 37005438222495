import { React, useEffect, useRef, useState } from 'react';
import GraphValues from '../../../../../api/graphValues';
import './mobileGraphSlider.css'
import MobileGraphSliderCard from './mobileGraphSliderCard';
import { format, parse } from 'date-fns';
import formatDataForChart from '../../../Dashboard-Hooks/formatChartData';

const MobileDashboardSlider = ({serial_number}) => {

    const graphValueApi = GraphValues();
    const isFetching = useRef(false);

    const [date, setDate] = useState(new Date());

    const [items, setItems] = useState({
        "battery_soc": {data: [], total: 0},
        "consumer_power": {data: [], total: 0},
        "grid_export_power": {data: [], total: 0},
        "grid_import_power": {data: [], total: 0},
        "pv_power": {data: [], total: 0}
    })

    const [loader, setLoader] = useState(false);
    const [showRetryMessage, setShowRetryMessage] = useState(false);
    const [highest_value, setHighest_Value] = useState(0);

    //retrieve chart data
    const RequestChartData = async() => {
        if (isFetching.current) return;

        isFetching.current = true;
        let attempts = 0;
        setLoader(true);
        setShowRetryMessage(false);

        const fetchData = async() => {
            
            try {
                const formattedDate = format(date, 'dd-MM-yyyy');
    
                const data = await graphValueApi.getGraphData(serial_number, parse(formattedDate, 'dd-MM-yyyy', new Date()), parse(formattedDate, 'dd-MM-yyyy', new Date()).setDate(parse(formattedDate, 'dd-MM-yyyy', new Date()).getDate() + 1), '1h');
                
                const updatedItems = {};
                let highestValue = 0;
                Object.entries(data).forEach(([key, value]) => {
                    const newItems = [];
                    let total = 0;
                    
                    for (let i = 0; i < value.time.length; i++) {
                        newItems.push({
                            date: new Date(value.time[i]),
                            value: Math.abs(value.values[i]),
                        });
                        total += Math.abs(value.values[i])
                        if (Math.abs(value.values[i]) > highestValue) {
                            highestValue = Math.abs(value.values[i])
                        }
                    }

                    updatedItems[key] = {
                        total: (total / 1000).toFixed(1),
                        data: formatDataForChart(newItems),
                    };
                });

                setHighest_Value(highestValue);
                setItems((prevItems) => ({
                    ...prevItems,
                    ...updatedItems
                }))
                console.log("Chart data received and state updated:", updatedItems);

            } catch (error) {
                console.error(error);
                
                if (attempts < 4) {
                    attempts++;
                    console.error("Fetching graph data failed retrying in 5s... attempt:", attempts)
                    setTimeout(fetchData, 5000)
                } else {
                    setLoader(false);
                    setShowRetryMessage(true);
                }
            } finally {
                setLoader(false)
                isFetching.current = false;
            }
        }

        await fetchData();
    }

    useEffect(() => {
        RequestChartData();

        const intervalId = setInterval(() => {
            RequestChartData();
        }, 15 * 60 * 1000);

        return () => clearInterval(intervalId);
    }, [date, serial_number]);

    return (
        <div className='Mobile__Graph__Slider'>
            <MobileGraphSliderCard value={items['consumer_power'].total} unit='kWh' name={'Consumption'} desc={'Manage consumption'} color={'#CE80EA'} data={items['consumer_power'].data} date={date} domain={[0, highest_value]} loading={loader}/>
            <MobileGraphSliderCard value={items['pv_power'].total} unit='kWh' name={'Production'} desc={'Control solar panels'} color={'#DABB50'} data={items['pv_power'].data} date={date} domain={[0, highest_value]} loading={loader}/>
            <MobileGraphSliderCard value={((items['battery_soc'].data[items['battery_soc'].data.length - 1]?.value) * 100).toFixed(1)} unit='%' name={'State_of_charge'} desc={'Battery capacity'} color={'#65D8A0'} data={items['battery_soc'].data} date={date} domain={[0, 1]} loading={loader}/>
            <MobileGraphSliderCard value={items['grid_export_power'].total} unit='kWh' name={'Export'} desc={'Grid control'} color={'#F88C8C'} data={items['grid_export_power'].data} date={date} domain={[0, highest_value]} loading={loader}/>
            <MobileGraphSliderCard value={items['grid_import_power'].total} unit='kWh' name={'Import'} desc={'Grid control'} color={'#46B6BD'} data={items['grid_import_power'].data} date={date} domain={[0, highest_value]} loading={loader}/>
            <div className='Mobile__Graph__Slider__Right__Side__Margin'></div>
        </div>
    )
}

export default MobileDashboardSlider;