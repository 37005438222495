import { useEffect, useState } from 'react';
import './mobiledashboardcurrentflow.css'

import battIcon from '../../../../../Images/battery_white_icon.png'
import gridIcon from '../../../../../Images/Grid_White_Icon.png'
import homeIcon from '../../../../../Images/Home_White_Icon.png'
import solarIcon from '../../../../../Images/Solar_White_icon.png'

import loaderGIF from '../../../../../Images/firn-embleem-animation.gif'

const MobileDashboardCurrentFlow = ({data, retryFlowData}) => {

    const [batteryPercentage, setBatteryPercentage] = useState(0);
    const darkMode = true 

    //flows
    const [flows, setFlows] = useState({
        SOLAR_BATT: false,
        SOLAR_CONS: false,
        SOLAR_GRID: false,
        BATT_CONS: false,
        BATT_GRID: false,
        GRID_BATT: false,
        GRID_CONS: false,
        CONS_GRID: false,
        CONS_BATT: false
    });

    const[values, setValues] = useState({
        SOLARW: 0,
        CONSW: 0,
        GRIDW: 0,
        BATTW: 0
    })

    useEffect(() => {
        if (data) {
            setValues({
                'SOLARW': Math.floor(data.SolarW),
                'CONSW': Math.floor(data.HomeW),
                'GRIDW': Math.floor(data.GridW),
                'BATTW': Math.floor(data.BatteryW)
            })
            setBatteryPercentage(data.BatteryP)
        }
    }, [data])

    // PARAMETERS
    const [duration, setDuration] = useState(5)
    const [colors, setColors] = useState({
        "SOLAR": "#DABB50",
        "GRID": '#F88C8C',
        "BATT": '#65D8A0',
        "CONS": '#CE80EA',
    })
    const [lineWidth, setStrokeWidth] = useState(2)

    useEffect(() => {
        const newFlows = {
            SOLAR_BATT: false,
            SOLAR_CONS: false,
            SOLAR_GRID: false,
            BATT_CONS: false,
            BATT_GRID: false,
            GRID_BATT: false,
            GRID_CONS: false,
            CONS_GRID: false,
            CONS_BATT: false,
            GRID_SOLAR: false,
        };

        //solar panels are producing
        if (values['SOLARW'] > 0) {
            //House is producing
            if (values['CONSW'] > 0) {
                newFlows.SOLAR_CONS = false;
                newFlows.GRID_CONS = false;
                newFlows.BATT_CONS = false;

                //battery is discharging
                if (values['BATTW'] > 0) {
                    newFlows.SOLAR_GRID = true;
                    newFlows.SOLAR_BATT = false;
                    newFlows.BATT_GRID = true;
                    newFlows.CONS_GRID = true
                    newFlows.GRID_BATT = false;
                    newFlows.CONS_BATT = false;

                //battery inactive
                } else if (values['BATTW'] == 0) {
                    newFlows.SOLAR_GRID = true;
                    newFlows.BATT_GRID = false;
                    newFlows.SOLAR_BATT = false;
                    newFlows.CONS_GRID = true;
                    newFlows.GRID_BATT = false;
                    newFlows.CONS_BATT = false;

                //battery is charging
                } else {
                    newFlows.SOLAR_BATT = true;
                    newFlows.BATT_GRID = false;

                    if (values['SOLARW'] > Math.abs(values['BATTW'])) {
                        newFlows.SOLAR_GRID = true;
                        newFlows.CONS_BATT = false;
                        newFlows.CONS_GRID = true;
                        newFlows.GRID_BATT = false;
                    } else if (values['SOLARW'] == Math.abs(values['BATTW'])) {
                        newFlows.SOLAR_GRID = false;
                        newFlows.CONS_BATT = false;
                        newFlows.CONS_GRID = true;
                        newFlows.GRID_BATT = false;
                    } else {
                        newFlows.SOLAR_GRID = false;

                        if (values['GRIDW'] > 0) {
                            newFlows.CONS_GRID = true;
                            newFlows.GRID_BATT = false;
                            newFlows.CONS_BATT = true;
                        } else {
                            newFlows.CONS_GRID = false;
                            newFlows.GRID_BATT = true;
                            newFlows.CONS_BATT = true;
                        }
                    }
                }
            } else if (values['CONSW'] == 0) {
                newFlows.SOLAR_CONS = false;
                newFlows.GRID_CONS = false;
                newFlows.BATT_CONS = false;
                newFlows.CONS_BATT = false;
                newFlows.CONS_GRID = false;

                //discharging
                if (values['BATTW'] > 0) {
                    newFlows.SOLAR_BATT = false;
                    newFlows.SOLAR_GRID = true;
                    newFlows.BATT_GRID = true;
                    newFlows.GRID_BATT = false;
                } else if (values['BATTW'] == 0) {
                    newFlows.SOLAR_BATT = false;
                    newFlows.SOLAR_GRID = true;
                    newFlows.BATT_GRID = false;
                    newFlows.GRID_BATT = false;
                
                //battery charging
                } else {
                    newFlows.SOLAR_BATT = true;
                    newFlows.BATT_GRID = false;

                    if (values['GRIDW'] > 0) {
                        newFlows.GRID_BATT = false;
                        newFlows.SOLAR_GRID = true;
                    } else {
                        newFlows.GRID_BATT = true;
                        newFlows.SOLAR_GRID = false;
                    }
                }

            //cons is negative
            } else {
                newFlows.CONS_BATT = false;
                newFlows.CONS_GRID = false;

                if (values['BATTW'] > 0) {
                    newFlows.SOLAR_BATT = false;
                    newFlows.GRID_BATT = false;

                    if (values['GRIDW'] > 0) {
                        newFlows.GRID_CONS = false;
                        newFlows.SOLAR_CONS = true;
                        newFlows.BATT_GRID = true;

                        

                        if (values['SOLARW'] > Math.abs(values['CONSW'])) {
                            newFlows.SOLAR_GRID = true;
                            newFlows.BATT_CONS = false;
                        } else if (values['SOLARW'] == Math.abs(values['CONSW'])) {
                            newFlows.SOLAR_GRID = false;
                            newFlows.BATT_CONS = false;
                        } else {
                            newFlows.SOLAR_GRID = false;
                            newFlows.BATT_CONS = true;
                        }
                    } else if (values['GRIDW'] == 0) {
                        newFlows.GRID_CONS = false;
                        newFlows.SOLAR_CONS = true;
                        newFlows.BATT_GRID = false;
                        newFlows.SOLAR_GRID = false;
                        newFlows.BATT_CONS = true;
                    } else {
                        newFlows.SOLAR_GRID = false;
                        newFlows.GRID_CONS = true;
                        newFlows.SOLAR_CONS = true;
                        newFlows.BATT_GRID = false;
                        newFlows.BATT_CONS = true;
                    }
                } else if (values['BATTW'] == 0) {
                    newFlows.SOLAR_BATT = false;
                    newFlows.GRID_BATT = false;
                    newFlows.BATT_CONS = false;
                    newFlows.BATT_GRID = false;

                    if (values['GRIDW'] > 0) {
                        newFlows.GRID_CONS = false;
                        newFlows.SOLAR_CONS = true;
                        newFlows.SOLAR_GRID = true;
                    } else if (values['GRIDW'] == 0) {
                        newFlows.GRID_CONS = false;
                        newFlows.SOLAR_CONS = true;
                        newFlows.SOLAR_GRID = false;
                    } else {
                        newFlows.GRID_CONS = true;
                        newFlows.SOLAR_CONS = true;
                        newFlows.SOLAR_GRID = false;
                    }
                } else {
                    newFlows.BATT_CONS = false;
                    newFlows.BATT_GRID = false;

                    if (values['GRIDW'] > 0) {
                        newFlows.SOLAR_CONS = true;
                        newFlows.SOLAR_GRID = true;
                        newFlows.SOLAR_BATT = true;
                        newFlows.GRID_CONS = false;
                        newFlows.GRID_BATT = false;
                    } else if (values['GRIDW'] == 0) {
                        newFlows.SOLAR_CONS = true;
                        newFlows.SOLAR_GRID = false;
                        newFlows.SOLAR_BATT = true;
                        newFlows.GRID_CONS = false;
                        newFlows.GRID_BATT = false;
                    } else {
                        newFlows.SOLAR_BATT = true;
                        newFlows.SOLAR_GRID = false;
                        newFlows.GRID_CONS = true;

                        if (values['SOLARW'] > Math.abs(values['BATTW'])) {
                            newFlows.SOLAR_CONS = true;
                            newFlows.GRID_BATT = false;
                        } else if (values['SOLARW'] == Math.abs(values['BATTW'])) {
                            newFlows.SOLAR_CONS = false;
                            newFlows.GRID_BATT = false;
                        } else {
                            newFlows.SOLAR_CONS = false;
                            newFlows.GRID_BATT = true;
                        }
                    }
                }
            }
        } else {
            newFlows.SOLAR_CONS = false;
            newFlows.SOLAR_BATT = false;
            newFlows.SOLAR_GRID = false;

            if (values['CONSW'] > 0) {
                newFlows.GRID_CONS = false;
                newFlows.BATT_CONS = false;
                
                if (values['GRIDW'] > 0) {
                    newFlows.GRID_BATT = false;
                    newFlows.CONS_GRID = true;

                    if (values['BATTW'] > 0) {
                        newFlows.BATT_GRID = true;
                        newFlows.CONS_BATT = false;
                    } else if (values['BATTW'] == 0) {
                        newFlows.BATT_GRID = false;
                        newFlows.CONS_BATT = false;
                    } else {
                        newFlows.CONS_BATT = true;
                        newFlows.BATT_GRID = false;
                    }
                } else if (values['GRIDW'] == 0)  {
                    newFlows.GRID_BATT = false;
                    newFlows.BATT_GRID = false;
                    newFlows.CONS_GRID = false;
                    newFlows.CONS_BATT = true;
                } else {
                    newFlows.CONS_GRID = false;
                    newFlows.BATT_GRID = false;
                    newFlows.GRID_BATT = true;
                    newFlows.CONS_BATT = true;
                }
            } else if (values['CONSW'] == 0) {
                newFlows.GRID_CONS = false;
                newFlows.BATT_CONS = false;
                newFlows.CONS_GRID = false;
                newFlows.CONS_BATT = false;

                if (values['BATTW'] > 0) {
                    newFlows.BATT_GRID = true;
                    newFlows.GRID_BATT = false;
                } else if (values['BATTW'] == 0) {
                    newFlows.BATT_GRID = false;
                    newFlows.GRID_BATT = false;
                } else {
                    newFlows.BATT_GRID = false;
                    newFlows.GRID_BATT = true;
                }
            } else {

                newFlows.CONS_BATT = false;
                newFlows.CONS_GRID = false;

                if (values['BATTW'] > 0) {
                    newFlows.BATT_CONS = true;
                    newFlows.GRID_BATT = false;

                    if (values['GRIDW'] > 0) {
                        newFlows.BATT_GRID = true;
                        newFlows.GRID_CONS = false;
                    } else if (values['GRIDW'] == 0) {
                        newFlows.BATT_GRID = false;
                        newFlows.GRID_CONS = false;
                    } else {
                        newFlows.BATT_GRID = false;
                        newFlows.GRID_CONS = true;
                    }
                } else if (values['BATTW'] == 0) {
                    newFlows.BATT_CONS = false;
                    newFlows.GRID_BATT = false;
                    newFlows.BATT_GRID = false;
                    newFlows.GRID_CONS = true;
                } else {
                    newFlows.BATT_CONS = false;
                    newFlows.GRID_BATT = true;
                    newFlows.BATT_GRID = false;
                    newFlows.GRID_CONS = true;
                }
            }
            if (values['SOLARW'] < 0) {
                newFlows.GRID_SOLAR = true;
            }
        }
        setFlows(newFlows);
    }, [values])

    if (!data) {
        return (
            <div className="Mobile__Currentflow">
                <div className="dashboard__savings__container" style={{justifyContent: 'center', alignItems: 'center'}}>
                    <img style={{ margin: 'auto' }} height={150} width={150} src={loaderGIF} alt="Loading..." />
                </div>
            </div>
        );
    }

    return (
        <div className="Mobile__Currentflow">
            <svg viewBox='0 0 375 375' style={{width: '90%', margin: '5% 0 0 5%', }}>
                <defs>
                    <linearGradient id="SolarToGridGradient"
                                    x1="0" y1="0" x2="1" y2="1">
                        <stop offset="0%" stopColor={colors["SOLAR"]}/>
                        <stop offset="100%" stopColor={colors["GRID"]}/>
                    </linearGradient>

                    <linearGradient id="ConsToBattGradient"
                                    x1="0" y1="1" x2="1" y2="0">
                        <stop offset="0%" stopColor={colors["BATT"]}/>
                        <stop offset="100%" stopColor={colors["CONS"]}/>
                    </linearGradient>

                    <linearGradient id="SolarToBattGradient"
                                    x1="0" y1="0" x2="0" y2="1">
                        <stop offset="0%" stopColor={colors["SOLAR"]}/>
                        <stop offset="100%" stopColor={colors["BATT"]}/>
                    </linearGradient>

                    <linearGradient id="SolarToConsGradient"
                                    x1="0" y1="0" x2="1" y2="0">
                        <stop offset="0%" stopColor={colors["SOLAR"]}/>
                        <stop offset="100%" stopColor={colors["CONS"]}/>
                    </linearGradient>

                    <linearGradient id="GridToConsGradient"
                                    x1="0" y1="1" x2="0" y2="0">
                        <stop offset="0%" stopColor={colors["GRID"]}/>
                        <stop offset="100%" stopColor={colors["CONS"]}/>
                    </linearGradient>

                    <linearGradient id="BattToGridGradient"
                                    x1="0" y1="0" x2="1" y2="0">
                        <stop offset="0%" stopColor={colors["BATT"]}/>
                        <stop offset="100%" stopColor={colors["GRID"]}/>
                    </linearGradient>
                </defs>

                {/* SOLAR TO GRID */}
                {flows.SOLAR_GRID && 
                    <g>
                        <path d="M105 105 L270 270" style={{fill:'none', stroke:'url(#SolarToGridGradient)', strokeWidth:lineWidth}}></path>
                        <circle r="5" fill='yellow'>
                            <animateMotion path='M107.5,107.5 267.5,267.5' dur={duration} repeatCount="indefinite"/>
                            <animate attributeName="fill" values={colors["SOLAR"] + ";" + colors["GRID"]} dur={duration} repeatCount="indefinite" />
                        </circle>
                    </g>
                }
                {/* GRID TO SOLAR */}
                {flows.GRID_SOLAR && 
                    <g>
                        <path d="M105 105 L270 270" style={{fill:'none', stroke:'url(#SolarToGridGradient)', strokeWidth:lineWidth}}></path>
                        <circle r="5" fill='yellow'>
                            <animateMotion path='M267.5,267.5 107.5,107.5' dur={duration} repeatCount="indefinite"/>
                            <animate attributeName="fill" values={colors["GRID"] + ";" + colors["SOLAR"]} dur={duration} repeatCount="indefinite" />
                        </circle>
                    </g>
                }

                {/* BATT TO CONS */}
                {flows.BATT_CONS && 
                    <g>
                    <path d="M270 105 L105 270" style={{fill:'none', stroke:'url(#ConsToBattGradient)', strokeWidth:lineWidth}}></path>
                        <circle r="5" fill='yellow'>
                            <animateMotion path='M107.5,267.5 267.5,107.5' dur={duration} repeatCount="indefinite"/>
                            <animate attributeName="fill" values={colors["BATT"] + ";" + colors["CONS"]} dur={duration} repeatCount="indefinite" />
                        </circle> 
                    </g>
                }

                {/* CONS TO BATT */}
                {flows.CONS_BATT && 
                    <g>
                    <path d="M105 270 L270 105" style={{fill:'none', stroke:'url(#ConsToBattGradient)', strokeWidth:lineWidth}}></path>
                        <circle r="5" fill='yellow'>
                            <animateMotion path='M267.5, 107.5 107.5, 267.5' dur={duration} repeatCount="indefinite"/>
                            <animate attributeName="fill" values={colors["CONS"] + ";" + colors["BATT"]} dur={duration} repeatCount="indefinite" />
                        </circle> 
                    </g>
                }
                
                {/* SOLAR TO CONS */}
                {flows.SOLAR_CONS && 
                    <g>
                        <path d="M110 100 L177.5 167.5 Q 187.5 177.5 197.5 167.5 L265 100" style={{fill:'none', stroke:'url(#SolarToConsGradient)', strokeWidth:lineWidth}}></path>
                        <circle r="5" fill='yellow'>
                            <animateMotion path='M112.5,102.5 177.5,167.5 Q 187.5,177.5 197.5,167.5 L262.5,102.5' dur={duration} repeatCount="indefinite"/>
                            <animate attributeName="fill" values={colors["SOLAR"] + ";" + colors["CONS"]} dur={duration} repeatCount="indefinite" />
                        </circle>
                    </g>
                }

                {/* SOLAR TO BATT */}
                {flows.SOLAR_BATT &&
                    <g>
                        <path d="M100 110 L167.5 177.5 Q 177.5 187.5 167.5 197.5 L100 265" style={{fill:'none', stroke:'url(#SolarToBattGradient)', strokeWidth:lineWidth}}></path>
                        <circle r="5" fill='yellow'>
                            <animateMotion path='M102.5,112.5 L167.5,177.5 Q 177.5,187.5 167.5,197.5 L102.5,262.5' dur={duration} repeatCount="indefinite"/>
                            <animate attributeName="fill" values={colors["SOLAR"] + ";" + colors["BATT"]} dur={duration} repeatCount="indefinite" />
                        </circle>
                    </g>
                }

                {/* GRID TO CONS */}
                {flows.GRID_CONS &&
                    <g>
                        <path d="M275 265 L207.5 197.5 Q 197.5 187.5 207.5 177.5 L275 110" style={{fill:'none', stroke:'url(#GridToConsGradient)', strokeWidth:lineWidth}}></path>
                        <circle r="5" fill='yellow'>
                            <animateMotion path='M272.5,262.5 L207.5,197.5 Q 197.5,187.5 207.5,177.5 L272.5,112.5' dur={duration} repeatCount="indefinite"/>
                            <animate attributeName="fill" values={colors["GRID"] + ";" + colors["CONS"]}  dur={duration} repeatCount="indefinite" />
                        </circle>
                    </g>
                }

                {/* CONS TO GRID */}
                {flows.CONS_GRID &&
                    <g>
                        <path d="M275 110 L207.5 177.5 Q 197.5 187.5 207.5 197.5 L275 265" style={{fill:'none', stroke:'url(#GridToConsGradient)', strokeWidth:lineWidth}}></path>
                        <circle r="5" fill='yellow'>
                            <animateMotion path="M272.5,112.5 L207.5,177.5 Q 197.5,187.5 207.5,197.5 L272.5,262.5" dur={duration} repeatCount="indefinite"/>
                            <animate attributeName="fill" values={colors["CONS"] + ";" + colors["GRID"]}  dur={duration} repeatCount="indefinite" />
                        </circle>
                    </g>
                }

                {/* GRID TO BATT */}
                {flows.GRID_BATT && 
                    <g>
                        <path d="M265 275 L197.5 207.5 Q 187.5 197.5 177.5 207.5 L110 275" style={{fill:'none', stroke:'url(#BattToGridGradient)', strokeWidth:lineWidth}}></path>
                        <circle r="5" fill='yellow'>
                            <animateMotion path='M262.5,272.5 L197.5,207.5 Q 187.5,197.5 177.5,207.5 L112.5,272.5' dur={duration} repeatCount="indefinite"/>
                            <animate attributeName="fill" values={colors["GRID"] + ";" + colors["BATT"]} dur={duration} repeatCount="indefinite" />
                        </circle>
                    </g>
                }
                
                {/* BATT TO GRID */}
                {flows.BATT_GRID && 
                    <g>
                        <path d="M265 275 L197.5 207.5 Q 187.5 197.5 177.5 207.5 L110 275" style={{fill:'none', stroke:'url(#BattToGridGradient)', strokeWidth:lineWidth}}></path>
                        <circle r="5" fill='blue'>
                            <animateMotion path='M112.5,272.5 L177.5,207.5 Q 187.5,197.5 197.5,207.5 L262.5,272.5' dur={duration} repeatCount="indefinite"/>
                            <animate attributeName="fill" values={colors["BATT"] + ";" + colors["GRID"]} dur={duration} repeatCount="indefinite" />
                        </circle>
                    </g>
                }

                {/*outer circles Solar, Consumption, Grid, Battery */}
                <circle cx="62.5" cy="62.5" r="60" stroke={colors["SOLAR"]} strokeWidth="3" fill='none'/>
                <circle cx="312.5" cy="62.5" r="60" stroke={colors["CONS"]} strokeWidth="3" fill='none'/>
                <circle cx="312.5" cy="312.5" r="60" stroke={colors["GRID"]} strokeWidth="3" fill='none'/>
                <circle cx="62.5" cy="312.5" r="60" stroke={colors["BATT"]} strokeWidth="3" fill='none'/>
                {/*inner circles Solar, Consumption, Grid, Battery */}
                <circle cx="62.5" cy="62.5" r="55" stroke={colors["SOLAR"]} opacity={0.50} strokeWidth="2" fill='none'/>
                <circle cx="312.5" cy="62.5" r="55" stroke={colors["CONS"]} opacity={0.50} strokeWidth="2" fill='none'/>
                <circle cx="312.5" cy="312.5" r="55" stroke={colors["GRID"]} opacity={0.50} strokeWidth="2" fill='none'/>
                <circle cx="62.5" cy="312.5" r="55" stroke={colors["BATT"]} opacity={0.50} strokeWidth="2" fill='none'/>

                <text x={62.5} y={90} textAnchor='middle' dominantBaseline="middle" fill={darkMode ? 'white' : 'black'}>{Math.abs(values['SOLARW'])} <tspan fill='#41B1F0'>W</tspan></text>
                <text x={62.5} y={340} textAnchor='middle' dominantBaseline="middle" fill={darkMode ? 'white' : 'black'}>{Math.abs(values['BATTW'])} <tspan fill='#41B1F0'>W</tspan></text>
                <text x={312.5} y={90} textAnchor='middle' dominantBaseline="middle" fill={darkMode ? 'white' : 'black'}>{Math.abs(values['CONSW'])} <tspan fill='#41B1F0'>W</tspan></text>
                <text x={312.5} y={340} textAnchor='middle' dominantBaseline="middle" fill={darkMode ? 'white' : 'black'}>{Math.abs(values['GRIDW'])} <tspan fill='#41B1F0'>W</tspan></text>

                <text x={62.5} y={297.5} fontSize={'0.9rem'} textAnchor='middle' dominantBaseline="middle" fill={darkMode ? 'white' : 'black'}>{batteryPercentage}%</text>

                <image y={270} x={37.5} height={50} href={darkMode ? battIcon : battIconBlack}></image>
                <image y={275} x={292.5} height={40} href={darkMode ? gridIcon : gridIconBlack}></image>
                <image y={26} x={295} height={40} href={darkMode ? homeIcon : homeIconBlack}></image>
                <image y={25} x={42.5} height={40} href={darkMode ? solarIcon : solarIconBlack}></image>

            </svg>
        </div>
    )
}

export default MobileDashboardCurrentFlow;