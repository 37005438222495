import React, { useState, useMemo } from 'react';
import { formatCurrency } from '../../../utils/helpers';
import CanvasJSReact from '@canvasjs/react-charts'

const CanvasJSChart = CanvasJSReact.CanvasJSChart

const Bijlage2 = ({ premies, voordelen, klant, tabelData, prices }) => {
    //const cycls = initialOrder.omvormer1 === "Firn Powerblock 233" ||initialOrder.omvormer1 === "Firn Powerblock 100"? 8000 : 6000;
    const factorDegressief = klant.degressief === 'ja' ? 0.25 : 0;
    const Deg = voordelen.degressief.cycliJaar / voordelen.degressief.cycli * factorDegressief;

    const lijstDeg = [];
    let startwaarde = 1;
    const aantalElementen = 15;
    for (let i = 0; i < aantalElementen; i++) {
        lijstDeg.push(startwaarde);
        startwaarde -= Deg;
    };
    const lijstDegSum = lijstDeg.reduce((acc, val) => acc + val, 0);
    const sumVoordeelTotaal = tabelData.voordeelTotaal.reduce((acc, val) => acc + val, 0)
    const [financiering, setFincanciering] = useState({ interest: 4, duur: 6 })
    const calculatePMT = (annualRate, years, presentValue) => {
        const monthlyRate = annualRate / 100 / 12;
        const numberOfPayments = years * 12;

        if (monthlyRate === 0) {
            return -presentValue / numberOfPayments;
        }

        const pvif = Math.pow(1 + monthlyRate, numberOfPayments);
        return -monthlyRate * (presentValue * pvif) / (pvif - 1);
    };

    const aflossing = useMemo(() => {
        let afl = { 0: -(prices.totaalExclBtw) + tabelData.premie };
        for (let i = 0; i < 15; i++) {
            afl[i + 1] = afl[i] - tabelData.voordeelTotaal[i];
        }
        return afl;
    }, [prices.totaalExclBtw, tabelData.premie, tabelData.voordeelTotaal]);

    const financieringChange = (e) => {
        const { name, value } = e.target;
        setFincanciering(prev => ({ ...prev, [name]: parseFloat(value) }));
    };

    const baseGraphConfig = {
        animationEnabled: true,
        exportEnabled: true,
        tooltip: { shared: true }
    };

    const graphAflossing = {
        ...baseGraphConfig,
        axisY: { title: 'Euro' },
        data: [{
            type: "line",
            name: "Zonder BESS",
            showInLegend: true,
            dataPoints: []
        },
        {
            type: "line",
            name: "Met BESS",
            showInLegend: true,
            dataPoints: []
        }]
    };

    const graphTerugverdientijd = {
        ...baseGraphConfig,
        axisY: {
            title: 'Euro',
            includeZero: true
        },
        data: [{
            type: "column",
            name: "Terugverdientijd",
            showInLegend: true,
            dataPoints: []
        }]
    };

    const graphCashflow = {
        ...baseGraphConfig,
        axisY: {
            title: 'Euro',
            includeZero: true
        },
        data: [{
            type: "column",
            name: "Zonder BESS",
            showInLegend: true,
            dataPoints: []
        },
        {
            type: "column",
            name: "Met BESS",
            showInLegend: true,
            dataPoints: []
        }]
    };

    if (tabelData.premie > 0) {
        graphCashflow.data.push({
            type: "column",
            name: "Winst Premie",
            showInLegend: true,
            dataPoints: [{
                y: tabelData.premie,
                x: 1
            }]
        });
    }

    for (let ja = 0; ja < 15; ja++) {
        const current = Math.round(tabelData.huidigeKost * 100) / 100;
        const fin = financiering.duur > ja
            ? Math.round((tabelData.huidigeKost + tabelData.voordeelTotaal[ja] + (-calculatePMT(financiering.interest, financiering.duur, prices.totaalExclBtw) * 12)) * 100) / 100
            : Math.round((tabelData.huidigeKost + tabelData.voordeelTotaal[ja]) * 100) / 100;

        graphAflossing.data[0].dataPoints.push({ y: current, label: ja + 1 });
        graphAflossing.data[1].dataPoints.push({ y: fin, label: ja + 1 });

        graphTerugverdientijd.data[0].dataPoints.push({
            y: Math.round(aflossing[ja + 1] * 100) / 100,
            x: ja + 1,
            color: Math.round(aflossing[ja + 1] * 100) / 100 < 0
                ? 'rgb(170, 0, 0)'
                : 'rgb(90, 220, 0)'
        });

        graphCashflow.data[0].dataPoints.push({ y: current, x: ja + 1 });
        graphCashflow.data[1].dataPoints.push({ y: fin, x: ja + 1 });
    }
    return (<>
        <div className='offerte__page'>
            <h2 className='offerte__header'>Offerte Bijlage 2</h2>
            <h3 className='offerte__header'>Vooruitzichten op 15 jaar</h3>
            <div data-html2canvas-ignore='true' className='offerte__segment'>
                <h4 className='offerte__header'>Financiering</h4>
                <div className='offerte__form-group offerte__form-group--inline'>
                    <div className='offerte__form-item'>
                        <label className='offerte__label' htmlFor='interest'>Interest</label>
                        <input
                            type="number"
                            id='interest'
                            name='interest'
                            value={financiering.interest}
                            step="0.01"
                            min="0"
                            onChange={financieringChange}
                            className='offerte__input'
                        />
                    </div>
                    <div className='offerte__form-item'>
                        <label className='offerte__label' htmlFor='duurInJaar'>Duur in jaar</label>
                        <input
                            type="number"
                            id='duurInJaar'
                            name='duur'
                            value={financiering.duur}
                            step="1"
                            min="0"
                            max="15"
                            onChange={financieringChange}
                            className='offerte__input'
                        />
                    </div>
                </div>
            </div>
            <table className='offerte__table offerte__table--celled offerte__table--definition'>
                <thead className='offerte__table__thead'>
                    <tr className='offerte__table__row'>
                        <th className='offerte__table__header'>Jaar</th>
                        <th className='offerte__table__header'>Huidige Kost</th>
                        <th className='offerte__table__header'>Voordeel Eigen Verbruik</th>
                        <th className='offerte__table__header'>Voordeel Day Ahead</th>
                        {klant["onbalans"] === "ja" && (<th className='offerte__table__header'>Voordeel Onbalans</th>)}
                        {klant["reserve"] === "ja" && (<th className='offerte__table__header'>Voordeel Reserve</th>)}
                        {klant["eboiler"] === "ja" && (<th className='offerte__table__header'>Voordeel E-Boiler</th>)}
                        <th className='offerte__table__header'>Voordeel Totaal</th>
                        <th className='offerte__table__header'>Kost Financiering</th>
                        <th className='offerte__table__header'>Totale Energiekost (incl. Financiering)</th>
                        <th className='offerte__table__header'>Cash Flow Verschil per Jaar</th>
                        <th className='offerte__table__header'>Terugverdientijd / opbrengst</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className='offerte__table__row'>
                        <td className='offerte__table__cell'>0</td>
                        <td className='offerte__table__cell disabled'></td>
                        <td className='offerte__table__cell disabled'></td>
                        <td className='offerte__table__cell disabled'></td>
                        {klant["onbalans"] === "ja" && (<td className='offerte__table__cell disabled'></td>)}
                        {klant["reserve"] === "ja" && (<td className='offerte__table__cell disabled'></td>)}
                        {klant["eboiler"] === "ja" && (<td className='offerte__table__cell disabled'></td>)}
                        <td className='offerte__table__cell disabled'></td>
                        <td className='offerte__table__cell disabled'></td>
                        <td className='offerte__table__cell disabled'></td>
                        <td className='offerte__table__cell disabled'></td>
                        <td className={`offerte__table__cell ${aflossing[0] < 0 ? 'offerte__table__cell--negative' : 'offerte__table__cell--positive'}`}>
                            {formatCurrency(aflossing[0])}
                        </td>
                    </tr>
                    {
                        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map((y) => {
                            return (
                                <tr className='offerte__table__row' key={y}>
                                    <td className='offerte__table__cell'>{y}</td>
                                    <td className='offerte__table__cell'>{formatCurrency(tabelData.huidigeKost)}</td>
                                    <td className='offerte__table__cell'>{formatCurrency(tabelData.voordeelEV* (lijstDeg[y - 1]))}</td>
                                    <td className='offerte__table__cell'>{formatCurrency(tabelData.voordeelDA * (lijstDeg[y - 1]))}</td>
                                    {klant["onbalans"] === "ja" && (<td className='offerte__table__cell'>{formatCurrency(tabelData.voordeelOnbalans * (lijstDeg[y - 1]))}</td>)}
                                    {klant["reserve"] === "ja" && (<td className='offerte__table__cell'>{formatCurrency(tabelData.voordeelReserve * (lijstDeg[y - 1]))}</td>)}
                                    {klant["eboiler"] === "ja" && (<td className='offerte__table__cell'>{formatCurrency(tabelData.voordeelEboiler)}</td>)}
                                    <td className='offerte__table__cell'>{formatCurrency(tabelData.voordeelTotaal[y - 1])}</td>
                                    <td className='offerte__table__cell'>
                                        {
                                            y <= financiering.duur &&
                                            <>{formatCurrency(-calculatePMT(financiering.interest, financiering.duur, prices.totaalExclBtw) * 12)}</>
                                        }
                                        {
                                            y > financiering.duur &&
                                            <>-</>
                                        }
                                    </td>
                                    <td className='offerte__table__cell'>
                                        {
                                            y <= financiering.duur &&
                                            <>{formatCurrency(tabelData.huidigeKost + tabelData.voordeelTotaal[y - 1] + (-calculatePMT(financiering.interest, financiering.duur, prices.totaalExclBtw) * 12))}</>
                                        }
                                        {
                                            y > financiering.duur &&
                                            <>{formatCurrency(tabelData.huidigeKost + tabelData.voordeelTotaal[y - 1])}</>
                                        }
                                    </td>
                                    <td className='offerte__table__cell'>
                                        {
                                            y <= financiering.duur &&
                                            <>{formatCurrency((-calculatePMT(financiering.interest, financiering.duur, prices.totaalExclBtw) * 12) + tabelData.voordeelTotaal[y - 1])}</>
                                        }
                                        {
                                            y > financiering.duur &&
                                            <>{formatCurrency(tabelData.voordeelTotaal[y - 1])}</>
                                        }
                                    </td>
                                    <td className={`offerte__table__cell ${aflossing[y] < 0 ? 'offerte__table__cell--negative' : 'offerte__table__cell--positive'}`}>
                                        {formatCurrency(aflossing[y])}
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
                <tfoot className='offerte__table__footer'>
                    <tr className='offerte__table__row'>
                        <th className='offerte__table__header'>Totaal</th>
                        <th className='offerte__table__header'>{formatCurrency(tabelData.huidigeKost * 15)}</th>
                        <th className='offerte__table__header'>{formatCurrency(tabelData.voordeelEV * lijstDegSum)}</th>
                        <th className='offerte__table__header'>{formatCurrency(tabelData.voordeelDA * lijstDegSum)}</th>
                        {klant["onbalans"] === "ja" && (<th className='offerte__table__header'>{formatCurrency(tabelData.voordeelOnbalans * lijstDegSum)}</th>)}
                        {klant["reserve"] === "ja" && (<th className='offerte__table__header'>{formatCurrency(tabelData.voordeelReserve * lijstDegSum)}</th>)}
                        {klant["eboiler"] === "ja" && (<th className='offerte__table__header'>{formatCurrency(tabelData.voordeelEboiler * 15)}</th>)}
                        <th className='offerte__table__header'>{formatCurrency(sumVoordeelTotaal)}</th>
                        <th className='offerte__table__header'>{formatCurrency((-calculatePMT(financiering.interest, financiering.duur, prices.totaalExclBtw) * 12) * financiering.duur)}</th>
                        <th className='offerte__table__header'>{formatCurrency((tabelData.huidigeKost * 15) + (sumVoordeelTotaal) + (-calculatePMT(financiering.interest, financiering.duur, prices.totaalExclBtw) * 12 * financiering.duur))}</th>
                        <th className='offerte__table__header'>{formatCurrency(((sumVoordeelTotaal) + (-calculatePMT(financiering.interest, financiering.duur, prices.totaalExclBtw) * 12 * financiering.duur)))}</th>
                        <th className='offerte__table__header'>{formatCurrency(aflossing[15])}</th>
                    </tr>
                    <tr className='offerte__table__row'>
                        <td className='offerte__table__cell' colSpan={5}>Investering</td>
                        <td className='offerte__table__cell' colSpan={5}>{formatCurrency(prices.totaalExclBtw)}</td>
                    </tr>
                    <tr className='offerte__table__row'>
                        <td className='offerte__table__cell' colSpan={5}>Winst</td>
                        <td className='offerte__table__cell' colSpan={5}>{formatCurrency(-(sumVoordeelTotaal + prices.totaalExclBtw))}</td>
                    </tr>
                </tfoot>
            </table>
            <table className='offerte__table offerte__table--celled offerte__table--definition'>
                <tbody>
                    {Object.keys(premies).map(key => (
                        <tr className='offerte__table__row' key={key}>
                            <td className='offerte__table__cell offerte__table__cell--four-wide'>{premies[key].label}</td>
                            <td className='offerte__table__cell offerte__table__cell--four-wide'>{premies[key].options[klant[key]].label}</td>
                            <td className='offerte__table__cell offerte__table__cell--four-wide'>{premies[key].options[klant[key]].value}%</td>
                            <td className={`offerte__table__cell ${tabelData[key] <= 0 ? 'offerte__table__cell--negative' : 'offerte__table__cell--positive'}`}>
                                {formatCurrency(tabelData[key])}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>

        <div className="offerte__divider pageBreak" data-html2canvas-ignore="true"></div>
        <div className="offerte__page">
            <div className='offerte__grafiek'>
                <h2 className='offerte__header'>Energiekost met financiering op {financiering.duur} jaar</h2>
                <CanvasJSChart options={graphAflossing} />
            </div>
            <div className='offerte__grafiek'>
                <h2 className='offerte__header'>Terugverdientijd</h2>
                <CanvasJSChart options={graphTerugverdientijd} />
            </div>
            <div className='offerte__grafiek'>
                <h2 className='offerte__header'>Cashflow</h2>
                <CanvasJSChart options={graphCashflow} />
            </div>
        </div>
    </>)
}

export default Bijlage2