import React from 'react';
import './homePageFooter.css';
import logoBrown from '../../../Images/firn-ver.png'
import pdf1 from '../../../Files/FIRN energy - Algemene voorwaarden.pdf'
import pdf2 from '../../../Files/FIRN energy - Algemene voorwaarden inzake aankoop goederen en hardware.pdf'
import { trackClickToSimulationPage } from '../../../Utils/simulationHelper';
import { useTranslation } from 'react-i18next';

const HomePageFooter = () => {
    const { t } = useTranslation();

    return (
        <footer className='homePage__footer'>
            <div className="homePage__footer__container">
                <div className="homePage__footer__section homePage__footer__logo-section">
                    <img src={logoBrown} alt="FIRN energy Logo" />
                </div>
                <div className='homePage__footer__column'>
                    <div className="homePage__footer__section homePage__footer__contact-section">
                        <h3>{t('homePageFooter.contactUs')}</h3>
                        <p>{t('homePageFooter.contactUsDescription')}</p>
                        <address>
                            Parkstraat 23<br />
                            8930 Menen<br />
                            +32 56 19 88 77<br />
                            <a href="mailto:sales@firnenergy.com">sales@firnenergy.com</a><br />
                        </address>
                        <p>{t('homePageFooter.vatNumber')}</p>
                    </div>
                    <div className="homePage__footer__section homePage__footer__follow-us-section">
                        <h3>{t('homePageFooter.followUs')}</h3>
                        <ul>
                            <li><a href="https://www.linkedin.com/company/firnenergy/">LinkedIn</a></li>
                            <li><a href="https://www.facebook.com/profile.php?id=61551960005278">Facebook</a></li>
                            <li><a href="https://www.youtube.com/@FIRNenergy">YouTube</a></li>
                        </ul>
                    </div>
                    <div className="homePage__footer__section homePage__footer__follow-partners-section">
                        <h3>{t('homePageFooter.ourPartners')}</h3>
                        <ul>
                            <li><a href="/Partners">{t('homePageFooter.partnersLink')}</a></li>
                        </ul>
                    </div>
                </div>
                <div className='homePage__footer__section homePage__footer__column'>
                    <div className="homePage__footer__section homePage__footer__solutions-section">
                        <h3>{t('homePageFooter.solutions')}</h3>
                        <ul>
                            <li><a href="/BatteryTower">{t('homePageFooter.solutionsLinks.batteryTower')}</a></li>
                            <li><a href="/Powerblock">{t('homePageFooter.solutionsLinks.powerblock')}</a></li>
                            <li><a href="/MiniPowerblock">{t('homePageFooter.solutionsLinks.miniPowerblock')}</a></li>
                            <li><a href="/Controller#firn-controller">{t('homePageFooter.solutionsLinks.controller')}</a></li>
                            <li><a href="/Container">{t('homePageFooter.solutionsLinks.container')}</a></li>
                            <li><a href="/e-Boiler">{t('homePageFooter.solutionsLinks.eboiler')}</a></li>
                        </ul>
                    </div>
                    <div className="homePage__footer__section homePage__footer__ai-section">
                        <h3>{t('homePageFooter.aiBased')}</h3>
                        <p><a href="/Controller">{t('homePageFooter.aiController')}</a></p>
                    </div>
                    <div className="homePage__footer__section homePage__footer__sectoren-section">
                        <h3>{t('homePageFooter.sectors')}</h3>
                        <ul>
                            <li><a href="/Industrie">{t('homePageFooter.sectorsLinks.industry')}</a></li>
                            <li><a href="/Residentieel">{t('homePageFooter.sectorsLinks.residential')}</a></li>
                            <li><a href="/Landbouw">{t('homePageFooter.sectorsLinks.agriculture')}</a></li>
                            <li><a href="/Horeca">{t('homePageFooter.sectorsLinks.hospitality')}</a></li>
                        </ul>
                    </div>
                </div>
                <div className="homePage__footer__section homePage__footer__request-simulation-section">
                    <div className="homePage__footer__section homePage__footer__follow-news-section">
                        <h3>{t('homePageFooter.latestNews')}</h3>
                        <ul>
                            <li><a href="/Nieuws">{t('homePageFooter.newsLink')}</a></li>
                        </ul>
                    </div>

                    <div className="homePage__footer__section homePage__footer__follow-news-section">
                        <h3>{t('homePageFooter.privacy')}</h3>
                        <ul>
                            <li><a href="/Cookiebeleid">{t('homePageFooter.privacyPolicy')}</a></li>
                        </ul>
                    </div>

                    <h3>{t('homePageFooter.requestSimulation')}</h3>
                    <p>{t('homePageFooter.simulationDescription')}</p>
                    <a href="/SimulatieAanvragen">
                        <button type="button" id="homePage__simulation" onClick={trackClickToSimulationPage}>{t('homePageFooter.simulationButton')}</button>
                    </a>
                </div>
            </div>
            <div className="homePage__footer-bottom">
                <a href={pdf1}>{t('homePageFooter.termsAndConditions')}</a><span> | </span>
                <a href={pdf2}>{t('homePageFooter.purchaseTerms')}</a>
            </div>
        </footer>
    );
}

export default HomePageFooter;