import './dashboardOverview.css'
import { useState, useEffect, useContext, useMemo } from 'react'
import { DarkModeContext } from '../../../context/DarkModeContext'

import DashboardFlows from './Flows/dashboardFlows'
import BatteryStats from './BatteryStats/batteryStats'
import GraphContainer from './Graph/graphContainer'
import DashboardSavings from './Savings/dashboardSavings'
import DashboardBlock from '../Dashboard-Components/dashboardBlock'
import useFlowData from '../Dashboard-Hooks/useFlowData'

const DashboardOverview = ({ serialNumber }) => {

    const { darkMode } = useContext(DarkModeContext);

    const [retryKey, setRetryKey] = useState(0);

    const flowData = useFlowData(serialNumber, retryKey);

    const [batteryState, setBatteryState] = useState('Stand-By')
    const [batteryInstallation, setBatteryInstallation] = useState(null);

    const updateBatteryState = (newBatteryState) => {
        setBatteryState(newBatteryState)
    }

    const retryFlowData = () => {
        setRetryKey(prevKey => prevKey + 1); // Increment key to force re-run
    };

    const memoizedFlowData = useMemo(() => {
        if (!flowData) return null;
        if (flowData === "Error") return "Error";
        return {
            SolarW: flowData.SolarW,
            GridW: flowData.GridW,
            BatteryW: flowData.BatteryW,
            HomeW: flowData.HomeW,
            BatteryP: flowData.BatteryP,
        };
    }, [flowData]);

    const updateBatteryInstallation = (installation) => {
        setBatteryInstallation(installation)
    }

    return (
        <div className={`Dashboard-Body-Overview-Container ${darkMode ? 'dark-mode' : 'light-mode'}`}>
            <div className='Dashboard-Body-Overview-Rows'>
                <div className='Dashboard-Body-Overview-Row'> 
                    <DashboardBlock key={'Flows'} addClassname={'Flows'}>
                        <DashboardFlows updateBattState={updateBatteryState} data={memoizedFlowData} retryFlowData={retryFlowData} />
                    </DashboardBlock>

                    <DashboardBlock key={'Profit'} addClassname={'Profit'}>
                        <DashboardSavings batteryInstallation={batteryInstallation} serialNumber={serialNumber} />
                    </DashboardBlock>

                    <DashboardBlock key={'Battery'} addClassname={'Battery'}>
                        <BatteryStats batteryStatus={batteryState ? batteryState : 'Stand-By'} serialNumber={serialNumber} batteryPercentage={memoizedFlowData ? memoizedFlowData.BatteryP : null} updateBatteryInstallation={updateBatteryInstallation} />
                    </DashboardBlock>
                </div>
                <div className='Dashboard-Body-Overview-Row'>
                    <DashboardBlock addClassname={'Graphs'}>
                        <GraphContainer serialNr={serialNumber} />
                    </DashboardBlock>
                </div>
            </div>
        </div>
    )
}

export default DashboardOverview


{/* <div style={{display: screenWidth > 1600 ? 'flex' : 'none' , backgroundColor: darkMode ? '#232323' : '', border: darkMode ? '#2E2E2E solid 2px' : '2px solid #D9D9D9', boxShadow: darkMode ? '' : '0 0 20px rgba(0, 0, 0, 0.1)'}} className='Dashboard-Body-Overview-Row-Element-Container Extra'>
                        <DashboardRelais Relais1={1000} Relais2={0}/>
                    </div> */}

{/* <div className='Dashboard-Body-Overview-Row'>
                    <div className='Dashboard-Body-Overview-Row-Element-Container Graphs' style={{backgroundColor: darkMode ? '#232323' : '', border: darkMode ? '#2E2E2E solid 2px' : '2px solid #D9D9D9', boxShadow: darkMode ? '' : '0 0 20px rgba(0, 0, 0, 0.1)'}}>
                        <GraphContainer serialNr={serialNr}/>
                    </div>
                </div> */}
{/* <div className='Dashboard-Body-Overview-Row'>
                    <div style={{display: screenWidth <= 1600 ? 'flex' : 'none' , backgroundColor: darkMode ? '#232323' : '', border: darkMode ? '#2E2E2E solid 2px' : '2px solid #D9D9D9', boxShadow: darkMode ? '' : '0 0 20px rgba(0, 0, 0, 0.1)'}} className='Dashboard-Body-Overview-Row-Element-Container Extra'>
                        <DashboardRelais Relais1={1000} Relais2={0}/>
                    </div>
                </div> */}