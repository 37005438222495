import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './adminDashboard.css';
import loaderGIF from './../../Images/firn-embleem-animation.gif';
import SignalDisplay from './signals/signalDisplay';
import OfferteController from './offerte/offerteController';
import logo from '../../Images/firn-hor-white 1.png';
import InstallationList from './Installations/installationList';
import SimulationRequestsList from './SimulationRequests/simulationRequestsList';
import NewsManager from './news/newsManager';
import DownloadExcel from './dayAhead/downloadExcel';
import MobileAdminDasboard from './Mobile-AdminDashboard/mobileAdminDashboard';

const AdminDashboard = () => {
    const { user, isAuthenticated, isLoading, logout } = useAuth0();
    const [loading, setLoading] = useState(true);
    const [username, setUsername] = useState("");
    const [picture, setPicture] = useState("");
    const [role, setRole] = useState("");
    const [activeTab, setActiveTab] = useState('Dashboard');
    const navigate = useNavigate();
    const [mobileDashboard, setMobileDashboard] = useState(false);

    const sidebarTabs = [
        {
            title: "Main",
            items: [
                { name: 'Dashboard', icon: 'fas fa-chart-pie' },
            ],
        },
        // {
        //     title: "User management",
        //     items: [
        //         { name: 'Installaties', icon: 'fas fa-users', roles: ["Admin", "SuperAdmin", "Installer"] }
        //     ],
        // },
        {
            title: "Signals",
            items: [
                { name: 'Batterij signalen', icon: 'fas fa-battery-full', roles: ["Admin", "SuperAdmin"] },
            ],
        },
        {
            title: "Tools",
            items: [
                { name: 'Offerte', icon: 'fas fa-file-invoice', roles: ["Admin", "SuperAdmin", "Installer", "Sales"] },
            ],
        },
        {
            title: "Database",
            items: [
                { name: 'Simulatieaanvragen', icon: 'fas fa-bell', roles: ["Admin", "SuperAdmin", "Sales"] },
                { name: 'Nieuwsberichten', icon: 'fas fa-newspaper', roles: ["Admin", "SuperAdmin"] },
            ],
        },
        {
            title: "Day Ahead",
            items: [
                { name: 'Data & Grafieken', icon: 'fas fa-download', roles: ["Admin", "SuperAdmin", "Sales"] },
            ],
        },
    ];

    useEffect(() => {
        if (isAuthenticated) {
            const roles = user?.['https://firnenergy.com/roles'] || [];

            if (roles.some(role => ["SuperAdmin", "Admin", "Installer", "Sales", "Multi-User"].includes(role))) {
                setUsername(user.nickname);
                setPicture(user.picture);
                setRole(roles[0]);
                setLoading(false);
            } else {
                navigate("https://firnenergy.com");
            }
        } else if (!isLoading) {
            navigate("https://firnenergy.com");
        }
    }, [isAuthenticated, isLoading, user, navigate]);

    const ToggleLoading = (val) => {
        setLoading(val);
    };

    const SwitchPage = (tab) => {
        setActiveTab(tab);
    };

    const getGreeting = () => {
        const hour = new Date().getHours();
        if (hour < 12) {
            return "Good morning";
        } else if (hour < 18) {
            return "Good afternoon";
        } else {
            return "Good evening";
        }
    };

    const formatUsername = (name) => {
        let formattedName = name.replace(/[_\.]/g, ' ');
        return formattedName.replace(/\b\w/g, (char) => char.toUpperCase());
    };

    useEffect(() => {
        const checkViewport = () => {
          setMobileDashboard(window.matchMedia("(max-width: 500px)").matches);
        };

        checkViewport();
    
        window.addEventListener("resize", checkViewport);
    
        return () => window.removeEventListener("resize", checkViewport);
    }, []);

    if (mobileDashboard) {
        return (
            <MobileAdminDasboard username={username} role={role} user={user}/>
        )
    }

    return (
        <div className="adminDashboard__app-container">
            <aside className="adminDashboard__sidebar">
                <img className='adminDashboard__logo' alt='Logo' src={logo} />
                <h2>Manager</h2>
                {sidebarTabs
                    .filter(section => section.items.some(item => !item.roles || item.roles.includes(role)))
                    .map((section, index) => (
                        <div key={index} className='adminDashboard__list'>
                            {section.title !== "Main" && (
                                <h4 className='adminDashboard__list__sub-title'>{section.title}</h4>
                            )}
                            {section.items
                                .filter(item => !item.roles || item.roles.includes(role))
                                .map((item, idx) => (
                                    <div
                                        key={idx}
                                        className={`adminDashboard__list-item ${activeTab === item.name ? 'adminDashboard__list-item--active' : ''}`}
                                        onClick={() => SwitchPage(item.name)}
                                    >
                                        <i className={`adminDashboard__icon ${item.icon}`}></i>
                                        {item.name}
                                    </div>
                                ))}
                        </div>
                    ))}
                <div className="adminDashboard__logout">
                    <button onClick={() => logout({ returnTo: window.location.origin })} className="adminDashboard__logout-button">
                        <i className="adminDashboard__icon fas fa-sign-out-alt"></i>
                        Logout
                    </button>
                </div>
            </aside>
            <main className="adminDashboard__main-content">
                <header className='adminDashboard__user-header'>
                    {role.includes('SuperAdmin', 'Admin') &&
                        <div style={{marginRight: '50px'}}>
                            <a 
                                href="https://firnenergy.grafana.net/login"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="adminDashboard__table__btn adminDashboard__table__installerdashboard__btn"
                            >
                                Go to Grafana Dashboard
                            </a>
                        </div>
                    }
                    <div>
                        <img className='adminDashboard__image' src={picture} alt="User" />
                    </div>
                    <div>
                        <div className='adminDashboard__username'>{username}</div>
                        <span className='adminDashboard__role'>{role}</span>
                    </div>
                </header>
                <section className="adminDashboard__body">
                    {loading && (
                        <div className='loading-container'>
                            <img className='loading-gif' src={loaderGIF} alt="Loading" />
                        </div>
                    )}
                    {activeTab === "Dashboard" && (
                        <div className="adminDashboard__dashboard">
                            <div className="adminDashboard__welcome-text">
                                <h1>{getGreeting()}!</h1>
                                <h1>{formatUsername(username)}</h1>
                            </div>
                            <div className="adminDashboard__content">
                                <InstallationList role={role} user={user}/>
                            </div>
                        </div>
                    )}
                    {activeTab === "Installaties" && <InstallationList role={role} user={user}/>}
                    {activeTab === "Batterij signalen" && <SignalDisplay />}
                    {activeTab === "Offerte" && <OfferteController />}
                    {activeTab === "Simulatieaanvragen" && <SimulationRequestsList />}
                    {activeTab === "Nieuwsberichten" && <NewsManager />}
                    {activeTab === "Data & Grafieken" && <DownloadExcel />}
                </section>
            </main>
        </div>
    );
};

export default AdminDashboard;
