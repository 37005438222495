import React, { useState, useEffect } from "react"
import { initialKlantData, convertToOptions } from '../../utils/formHelper'
import DayAhead from "../DayAhead/dayAhead"

const Klant = ({
    onEdit, klant, reps, premies, voordeel_opties, pay,
    setAveragePrice, setAverageLowPrice, setAverageHighPrice,
    isPeriodConfirmed, setIsPeriodConfirmed, setRequiredFields
}) => {
    const [repData, setRepData] = useState([])
    const [klantData, setKlantData] = useState(klant)
    const [paymentOpties, setPaymentOpties] = useState([])
    const [emailError, setEmailError] = useState("")
    const [useOwnContact, setUseOwnContact] = useState(false);

    useEffect(() => {
        setRepData(convertToOptions(reps.values))
        setPaymentOpties(convertToOptions(pay))
    }, [reps, pay])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setKlantData(prevData => {
            const newData = { ...prevData, [name]: value };
            
            // Als reserve op 'ja' wordt gezet en onbalans ook 'ja' is
            if (name === 'reserve' && value === 'ja' && prevData.onbalans === 'ja') {
                newData.onbalans = 'nee';
            }
            
            // Als onbalans op 'ja' wordt gezet en reserve ook 'ja' is
            if (name === 'onbalans' && value === 'ja' && prevData.reserve === 'ja') {
                newData.reserve = 'nee';
            }
            
            return newData;
        });
    }

    const updateOnbalans = () => {
        klantData.onbalans = "ja";
        klantData.eboiler = "nee";
        klantData.reserve = "nee"
    };

    const handleCheckboxChange = () => {
        setUseOwnContact(prevState => {
            const newState = !prevState;
            setKlantData(prevData => ({
                ...prevData,
                eigencontactpersoon: newState,
                eigencontactnaam: newState ? prevData.eigencontactnaam : "",
                eigencontacttel: newState ? prevData.eigencontacttel : "",
                eigencontactemail: newState ? prevData.eigencontactemail : "",
            }));

            if (newState) {
                setRequiredFields((fields) => [
                    ...fields,
                    "eigencontactnaam",
                    "eigencontacttel",
                    "eigencontactemail",
                ]);
            } else {
                setRequiredFields((fields) =>
                    fields.filter(
                        (field) =>
                            !["eigencontactnaam", "eigencontacttel", "eigencontactemail"].includes(
                                field
                            )
                    )
                );
            }

            return newState;
        });
    };

    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (name === "email") {
            validateEmail(value);
        }
    }

    useEffect(() => {
        onEdit(klantData)
        setUseOwnContact(klantData.eigencontactpersoon)
    }, [klantData, onEdit, klantData.eigencontactpersoon])

    const eraseForm = () => {
        setKlantData(initialKlantData)
        setEmailError("")
    }

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(email)) {
            setEmailError("Please enter a valid email address.");
        } else {
            setEmailError("");
        }
    }

    return (
        <div className="offerte__container offerte__container--left-aligned">
            <form onSubmit={(e) => e.preventDefault()} className="offerte__form">
                <div className="offerte__form-item">
                    <div className="offerte__toggle-inline-container">
                        <label htmlFor="newversion-toggle" className="offerte__label">
                            Nieuwe versie offerte?
                        </label>
                        <div className="offerte__toggle-wrapper">
                            <input
                                type="checkbox"
                                id="newversion-toggle"
                                className="offerte__toggle-input"
                                checked={klant.newversion === 'yes'}
                                onChange={(e) => {
                                    handleChange({
                                        target: {
                                            name: 'newversion',
                                            value: e.target.checked ? 'yes' : 'no'
                                        }
                                    });
                                }}
                            />
                            <label
                                htmlFor="newversion-toggle"
                                className="offerte__toggle-label"
                            >
                                <span className="offerte__toggle-switch"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="offerte__form-group offerte__form-group--inline">
                    <div className="offerte__form-item--special">
                        <label className="offerte__label" htmlFor="num">Referentie offerte</label>
                        <input
                            className="offerte__input"
                            type="text"
                            id="num"
                            name="num"
                            placeholder="Referentie offerte"
                            value={klantData.num}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="offerte__form-item">
                        <label className="offerte__label" htmlFor="contactpersoonFirn">Uw FIRN contactpersoon</label>
                        <select
                            className="offerte__select"
                            id="contactpersoonFirn"
                            name="contactpersoonFirn"
                            value={klantData.contactpersoonFirn}
                            onChange={handleChange}
                        >
                            {repData.map((opt, index) => (
                                <option key={index} value={opt.value}>
                                    {opt.text}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="offerte__form-item offerte__checkbox">
                        <label className="offerte__label" htmlFor="useOwnContact">
                            Eigen contactpersoon invullen?
                        </label>
                        <input
                            type="checkbox"
                            id="useOwnContact"
                            name="useOwnContact"
                            checked={useOwnContact}
                            onChange={handleCheckboxChange}
                        />
                    </div>
                </div>
                {useOwnContact && (
                    <div>
                        <div className="offerte__form-group">
                            <label className="offerte__label" htmlFor="eigenContactNaam">Eigen Contactpersoon</label>
                            <input
                                className="offerte__input"
                                type="text"
                                id="eigenContactNaam"
                                name="eigencontactnaam"
                                placeholder="Naam eigen contactpersoon"
                                value={klantData.eigencontactnaam}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="offerte__form-group offerte__form-group--inline">
                            <div className="offerte__form-item">
                                <label className="offerte__label" htmlFor="eigenContactTel">Telefoonnummer</label>
                                <input
                                    className="offerte__input"
                                    type="tel"
                                    id="eigenContactTel"
                                    name="eigencontacttel"
                                    placeholder="Telefoonnummer eigen contactpersoon"
                                    value={klantData.eigencontacttel}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="offerte__form-item">
                                <label className="offerte__label" htmlFor="eigenContactEmail">E-mail</label>
                                <input
                                    className="offerte__input"
                                    type="email"
                                    id="eigenContactEmail"
                                    name="eigencontactemail"
                                    placeholder="E-mail eigen contactpersoon"
                                    value={klantData.eigencontactemail}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {emailError && <span className="offerte__warning">{emailError}</span>}
                            </div>
                        </div>
                    </div>
                )}
                <div className="offerte__divider" />
                <h2 className="offerte__header">Contact</h2>
                <div className="offerte__form-group">
                    <label className="offerte__label" htmlFor="bedrijf">Bedrijf</label>
                    <input
                        className="offerte__input"
                        type="text"
                        id="bedrijf"
                        name="bedrijf"
                        placeholder="Bedrijf"
                        value={klantData.bedrijf}
                        onChange={handleChange}
                    />
                </div>
                <div className="offerte__form-group">
                    <label className="offerte__label" htmlFor="contactpersoon">Contactpersoon</label>
                    <input
                        className="offerte__input"
                        type="text"
                        id="contactpersoon"
                        name="contactpersoon"
                        placeholder="Contactpersoon"
                        value={klantData.contactpersoon}
                        onChange={handleChange}
                    />
                </div>
                <div className="offerte__form-group offerte__form-group--inline">
                    <div className="offerte__form-item">
                        <label className="offerte__label" htmlFor="email">Email</label>
                        <input
                            className="offerte__input"
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Email"
                            value={klantData.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            autoComplete="email"
                        />
                        {emailError && <span className="offerte__warning">{emailError}</span>}
                    </div>
                    <div className="offerte__form-item">
                        <label className="offerte__label" htmlFor="tel">Telefoonnummer</label>
                        <input
                            className="offerte__input"
                            type="tel"
                            id="tel"
                            name="tel"
                            placeholder="Telefoonnummer"
                            value={klantData.tel}
                            onChange={handleChange}
                            autoComplete="tel"
                        />
                    </div>
                </div>
                <div className="offerte__form-group">
                    <label className="offerte__label" htmlFor="adres">Straat + Nummer</label>
                    <input
                        className="offerte__input"
                        type="text"
                        id="adres"
                        name="adres"
                        placeholder="Straat + Nummer"
                        value={klantData.adres}
                        onChange={handleChange}
                    />
                </div>
                <div className="offerte__form-group offerte__form-group--inline">
                    <div className="offerte__form-item--special">
                        <label className="offerte__label" htmlFor="postcode">Postcode</label>
                        <input
                            className="offerte__input"
                            type="text"
                            id="postcode"
                            name="postcode"
                            placeholder="Postcode"
                            value={klantData.postcode}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="offerte__form-item">
                        <label className="offerte__label" htmlFor="gemeente">Gemeente</label>
                        <input
                            className="offerte__input"
                            type="text"
                            id="gemeente"
                            name="gemeente"
                            placeholder="Gemeente"
                            value={klantData.gemeente}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="offerte__form-item">
                        <label className="offerte__label" htmlFor="land">Land</label>
                        <input
                            className="offerte__input"
                            type="text"
                            id="land"
                            name="land"
                            placeholder="Land"
                            value={klantData.land}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="offerte__form-group offerte__form-group--inline">
                    <div className="offerte__form-item--special">
                        <label className="offerte__label" htmlFor="korting">Commerciële korting</label>
                        <input
                            className="offerte__input"
                            type="number"
                            id="korting"
                            name="korting"
                            step="1"
                            placeholder="Commerciële korting"
                            value={klantData.korting}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="offerte__form-item">
                        <label className="offerte__label" htmlFor="btw">BTW Nummer</label>
                        <input
                            className="offerte__input"
                            type="text"
                            id="btw"
                            name="btw"
                            placeholder="BTW Nummer"
                            value={klantData.btw}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="offerte__form-group offerte__form-group--inline">
                    <div className="offerte__form-item--special">
                        <label className="offerte__label" htmlFor="geldigTot">Offerte geldig tot</label>
                        <input
                            className="offerte__input"
                            type="date"
                            id="geldigTot"
                            name="geldigTot"
                            value={klantData.geldigTot}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="offerte__form-item">
                        <label className="offerte__label" htmlFor="betalingsvoorwaarde">Betalingsvoorwaarden</label>
                        <select
                            className="offerte__select"
                            id="betalingsvoorwaarde"
                            name="betalingsvoorwaarde"
                            value={klantData.betalingsvoorwaarde}
                            onChange={handleChange}
                        >
                            <option value="" disabled>Selecteer betalingsvoorwaarde</option>
                            {paymentOpties.map((opt, index) => (
                                <option key={index} value={opt.value}>
                                    {opt.text}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="offerte__divider" />
                <DayAhead
                    setAveragePrice={setAveragePrice}
                    setAverageLowPrice={setAverageLowPrice}
                    setAverageHighPrice={setAverageHighPrice}
                    isPeriodConfirmed={isPeriodConfirmed}
                    setIsPeriodConfirmed={setIsPeriodConfirmed}
                />
                <div className="offerte__divider" />
                <h2 className="offerte__header">Verbruik</h2>
                <div className="offerte__form-group offerte__form-group--inline">
                    <div className="offerte__form-item">
                        <label className="offerte__label" htmlFor="verbruik">Jaarverbruik in kWh</label>
                        <input
                            className="offerte__input"
                            type="number"
                            id="verbruik"
                            name="verbruik"
                            min="0"
                            placeholder="Jaarverbruik in kWh"
                            value={klantData.verbruik}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="offerte__form-item">
                        <label className="offerte__label" htmlFor="productie">Productie PV in kWh</label>
                        <input
                            className="offerte__input"
                            type="number"
                            id="productie"
                            name="productie"
                            min="0"
                            placeholder="Productie PV in kWh"
                            value={klantData.productie}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="offerte__form-item">
                        <label className="offerte__label" htmlFor="injectie">Injectie in kWh</label>
                        <input
                            className="offerte__input"
                            type="number"
                            id="injectie"
                            name="injectie"
                            min="0"
                            placeholder="Injectie in kWh"
                            value={klantData.injectie}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="offerte__divider" />
                <h2 className="offerte__header">Prijs</h2>
                <div className="offerte__form-group offerte__form-group--inline">
                    <div className="offerte__form-item">
                        <label className="offerte__label" htmlFor="aankoop">Aankoop totaalkost per kWh</label>
                        <input
                            className="offerte__input"
                            type="number"
                            id="aankoop"
                            name="aankoop"
                            min="0"
                            step="0.001"
                            placeholder="Aankoop totaalkost per kWh"
                            value={klantData.aankoop}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="offerte__form-item">
                        <label className="offerte__label" htmlFor="verkoop">Verkoop injectie PV per kWh</label>
                        <input
                            className="offerte__input"
                            type="number"
                            id="verkoop"
                            name="verkoop"
                            min="0"
                            step="0.001"
                            placeholder="Verkoop injectie PV per kWh"
                            value={klantData.verkoop}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="offerte__form-item">
                        <label className="offerte__label" htmlFor="aankoopExcl">Aankoop elektriciteit excl. Transport etc</label>
                        <input
                            className="offerte__input"
                            type="number"
                            id="aankoopExcl"
                            name="aankoopExcl"
                            min="0"
                            step="0.001"
                            placeholder="Aankoop elektriciteit excl. Transport etc"
                            value={klantData.aankoopExcl}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="offerte__divider" />
                <h2 className="offerte__header">Aansluitvermogen installatie</h2>
                <div className="offerte__form-group">
                    <label className="offerte__label" htmlFor="aansluitvermogen">Aansluitvermogen installatie (in kVA)</label>
                    <input
                        className="offerte__input"
                        type="number"
                        id="aansluitvermogen"
                        name="aansluitvermogen"
                        min="0"
                        step="1"
                        placeholder="Aansluitvermogen installatie (in kVA)"
                        value={klantData.aansluitvermogen}
                        onChange={handleChange}
                    />
                </div>
                <div className="offerte__divider" />

                {klant.newversion === "no" &&
                    updateOnbalans()
                }
                {klant.newversion === "yes" && (
                    <>
                        <h2 className="offerte__header">Voordeel opties</h2>
                        <div className="offerte__form-group offerte__form-group--inline">
                            {Object.keys(voordeel_opties).map((o) => (
                                <div className="offerte__form-item" key={o}>
                                    <span className="offerte__label">{voordeel_opties[o].label}</span>
                                    {Object.keys(voordeel_opties[o].options).map((v) => (
                                        <div key={v} className="offerte__form-radio">
                                            <input
                                                className="offerte__radio-input"
                                                type="radio" npm
                                                id={`voordeel_optie-${o}-${v}`}
                                                name={o}
                                                value={v}
                                                checked={klantData[o] === v}
                                                onChange={handleChange}
                                                autoComplete="off"
                                            />
                                            <label className="offerte__radio-label" htmlFor={`premie-${o}-${v}`}>
                                                {voordeel_opties[o].options[v].label}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </>
                )}
                <div className="offerte__form-group">
                    {klantData["onbalans"] === "ja" && (
                        <>
                            {klantData["reserve"] === "ja" ? (klantData["reserve"] = "nee") : null}
                            <h2 className="offerte__header">Onbalans Simulatie Berekening</h2>
                            <label className="offerte__label" htmlFor="simOnbalans"> Onbalans Simulatie Berekening (in %)
                            </label>
                            <input
                                className="offerte__input"
                                type="number"
                                id="simOnbalans"
                                name="simOnbalans"
                                min="0"
                                max="100"
                                step="1"
                                placeholder="Onbalans Simulatie Berekening (in %)"
                                value={klantData.simOnbalans}
                                onChange={handleChange}
                            />
                        </>
                    )}

                    {klantData["reserve"] === "ja" && (
                        <>
                            {klantData["onbalans"] === "ja" ? (klantData["onbalans"] = "nee") : null}
                            <h2 className="offerte__header">Reserve Simulatie Berekening</h2>
                            <div className="offerte__form-group offerte__form-group--inline">
                                <div className="offerte__form-item">
                                    <label className="offerte__label" htmlFor="simRes">Reserve revenu share (in %)
                                    </label>
                                    <input
                                        className="offerte__input"
                                        type="number"
                                        id="simRes"
                                        name="simRes"
                                        min="0"
                                        max="100"
                                        step="1"
                                        placeholder="Reserve revenu share (in %)"
                                        value={klantData.simRes}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="offerte__form-item">
                                    <label className="offerte__label" htmlFor="simRes_battper">Batterij % gebruikt in de reserve markt (in%)
                                    </label>
                                    <input
                                        className="offerte__input"
                                        type="number"
                                        id="simRes_battper"
                                        name="simRes_battper"
                                        min="0"
                                        max="100"
                                        step="1"
                                        placeholder="Batterij percentage (in %)"
                                        value={klantData.simRes_battper}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="offerte__form-item">
                                    <label className="offerte__label" htmlFor="simRes_factorper">Factor combinatie DA en FCR/aFRR markt  (in%)
                                    </label>
                                    <input
                                        className="offerte__input"
                                        type="number"
                                        id="simRes_factorper"
                                        name="simRes_factorper"
                                        min="0"
                                        max="100"
                                        step="1"
                                        placeholder="Factor combinatie DA en FCR/aFRR markt"
                                        value={klantData.simRes_factorper}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </>
                    )}

                    {klantData["eboiler"] === "ja" && (
                        <>
                            <h2 className="offerte__header">Eboiler parameters</h2>
                            <div className="offerte__form-group offerte__form-group--inline">
                                <div className="offerte__form-item--special">
                                    <label className="offerte__label" htmlFor="Grootte buffervat">Grootte buffervat in liter</label>
                                    <input
                                        className="offerte__input"
                                        type="number"
                                        id="Grootte_buffervat"
                                        name="Grootte_buffervat"
                                        placeholder="Grootte buffervat"
                                        value={klantData.Grootte_buffervat}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="offerte__form-item">
                                    <label className="offerte__label" htmlFor="Temperatuur water">Temperatuur water in graden</label>
                                    <input
                                        className="offerte__input"
                                        type="number"
                                        id="Temperatuur_water"
                                        name="Temperatuur_water"
                                        placeholder="Temperatuur water"
                                        value={klantData.Temperatuur_water}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="offerte__form-item">
                                    <label className="offerte__label" htmlFor="Aantal cycli">Aantal cycli</label>
                                    <input
                                        className="offerte__input"
                                        type="number"
                                        id="Aantal_cycli"
                                        name="Aantal_cycli"
                                        placeholder="Aantal cycli"
                                        value={klantData.Aantal_cycli}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="offerte__form-item">
                                    <label className="offerte__label" htmlFor="Prijs Mazout">Prijs Mazout in euro per MWh</label>
                                    <input
                                        className="offerte__input"
                                        type="number"
                                        id="Prijs_Mazout"
                                        name="Prijs_Mazout"
                                        placeholder="Prijs mazout"
                                        value={klantData.Prijs_Mazout}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <div className="offerte__divider" />
                <h2 className="offerte__header">Premies</h2>
                <div className="offerte__form-group offerte__form-group--inline">
                    {Object.keys(premies).map((o) => (
                        <div className="offerte__form-item" key={o}>
                            <span className="offerte__label">{premies[o].label}</span>
                            {Object.keys(premies[o].options).map((v) => (
                                <div key={v} className="offerte__form-radio">
                                    <input
                                        className="offerte__radio-input"
                                        type="radio"
                                        id={`premie-${o}-${v}`}
                                        name={o}
                                        value={v}
                                        checked={klantData[o] === v}
                                        onChange={handleChange}
                                        autoComplete="off"
                                    />
                                    <label className="offerte__radio-label" htmlFor={`premie-${o}-${v}`}>
                                        {premies[o].options[v].label}
                                    </label>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
                <div className="offerte__divider" />
                <button type="button" className="offerte__reset-button" onClick={eraseForm}>
                    Reset
                </button>
            </form>
        </div>
    )
}

export default Klant