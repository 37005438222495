import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import defaultLogo from './../../../../../../Images/firn-ver.png';
import { useAuth0 } from '@auth0/auth0-react';
import User from '../../../../../../api/user';
import axios from 'axios';

const HeaderOfferte = ({ install, klant, reps }) => {
    const offerte = klant.num;
    const { user, isAuthenticated, isLoading } = useAuth0();
    const userApi = User();
    const [logoSRC, setLogoSRC] = useState(defaultLogo);

    const getBase64Image = async (url) => {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            return new Promise((resolve, reject) => {
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
            });
        } catch (error) {
            console.error('Error fetching image:', error);
            return defaultLogo;
        }
    };

    const getUserLogo = async () => {
        try {
            const domain = process.env.REACT_APP_AUTH0_DOMAIN;
            const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
            const token = await userApi.getManagementApiToken();

            const metadataResponse = await axios.get(userDetailsByIdUrl, {
                headers: { Authorization: `Bearer ${token}` }
            });

            const imageUrl = metadataResponse.data.app_metadata.image;
            if (imageUrl && (imageUrl.startsWith('http://') || imageUrl.startsWith('https://'))) {
                const base64Image = await getBase64Image(imageUrl);
                setLogoSRC(base64Image);
            } else {
                setLogoSRC(defaultLogo);
            }
        } catch (error) {
            setLogoSRC(defaultLogo);
        }
    };

    useEffect(() => {
        if (user && isAuthenticated && !isLoading) {
            getUserLogo();
        }
    }, [user, isAuthenticated, isLoading]);

    return (
        <>
            <div className='offerte__segment offerte__segment--inverted offerte__segment--header' style={{ backgroundColor: `#${install.color}` }}>
                <div className='offerte__grid offerte__grid--equal-width'>
                    <div className='offerte__column'>
                        <h4 className='offerte__header offerte__header--inverted'>{install.title}</h4>
                        {install.adres}<br />
                        {install.BTW}
                        <div className='offerte__divider'></div>
                        {install.tel && (
                            <>
                                <i aria-hidden='true' className='offerte__icon fas fa-phone'></i>
                                {install.tel}<br />
                            </>
                        )}
                        {install.mail && (
                            <>
                                <i aria-hidden='true' className='offerte__icon fas fa-envelope'></i>
                                {install.mail}<br />
                            </>
                        )}
                        {install.web && (
                            <>
                                <i aria-hidden='true' className='offerte__icon fas fa-globe'></i>
                                {install.web}
                            </>
                        )}
                    </div>

                    <div className='offerte__column offerte__column--center-aligned'>
                        <div className='offerte__image offerte__image--small offerte__image--centered'>
                            <img src={logoSRC} alt='User Logo' />
                        </div>
                    </div>

                    <div className='offerte__column'>
                        <h4 className='offerte__header offerte__header--inverted'>Uw contact</h4>
                        {klant.eigencontactpersoon ? (
                            <>
                                <h5 className='offerte__header offerte__header--inverted'>{klant.eigencontactnaam}</h5>
                                <>
                                    <i aria-hidden='true' className='offerte__icon fas fa-phone'></i>
                                    {klant.eigencontacttel}<br />
                                </>
                                <>
                                    <i aria-hidden='true' className='offerte__icon fas fa-envelope'></i>
                                    {klant.eigencontactemail}<br />
                                </>
                            </>
                        ) : (
                            klant.contactpersoonFirn && (
                                <>
                                    <h5 className='offerte__header offerte__header--inverted'>
                                        {reps.values[klant.contactpersoonFirn].name}
                                    </h5>
                                    <i aria-hidden='true' className='offerte__icon fas fa-phone'></i>
                                    {reps.values[klant.contactpersoonFirn].phone}<br />
                                    <i aria-hidden='true' className='offerte__icon fas fa-envelope'></i>
                                    {reps.values[klant.contactpersoonFirn].mail}<br />
                                </>
                            )
                        )}
                    </div>
                </div>
            </div>

            <div className='offerte__segment'>
                <div className='offerte__grid'>
                    <div className='offerte__column offerte__column--six-wide offerte__column--center-aligned'>
                        <h5 className='offerte__header'>Nummer offerte:</h5>
                        {offerte}
                        <h5 className='offerte__header'>Datum offerte:</h5>
                        {format(new Date(), 'dd/MM/yyyy')}
                    </div>
                    <div className='offerte__column offerte__column--left-aligned offerte__column--eight-wide'>
                        {klant.contactpersoon && (
                            <>
                                <h5 className='offerte__header'>Ter attentie van: {klant.bedrijf}</h5>
                                {klant.contactpersoon}<br />
                                {klant.adres}<br />
                                {klant.postcode} {klant.gemeente}<br />
                                {klant.land}
                                <div className='offerte__divider'></div>
                                <i aria-hidden='true' className='offerte__icon fas fa-phone'></i>
                                {klant.tel}<br />
                                <i aria-hidden='true' className='offerte__icon fas fa-envelope'></i>
                                {klant.email}<br />
                                BTW {klant.btw}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default HeaderOfferte;
