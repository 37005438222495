import { useEffect, useState } from 'react';
import './mobileFooterNavbar.css'

import Mobile_Settings from '../../../../Images/Mobile_Settings.png'
import Mobile_Dashboard from '../../../../Images/Mobile_Dashboard.png'
import Mobile_Stats from '../../../../Images/Mobile_Stats.png'
import Mobile_Signal from '../../../../Images/Mobile_Signal.png'

const generateClipPath = (quartal) => {
    const basePoints = [[0, 0], [0.8333, 1], [1.666, 2.5], [2.5, 5], [2.916, 7],[3.333, 9], [4.165, 14], [5, 20], [5.831, 27.5], [6.666, 35], [7.5, 42.5], [8.333, 50], [9.166, 55], [10, 57.5], [10.833, 58.5], [11.666, 59], [12.5, 59], [13.333, 59], [14.165, 58.5], [15, 57.5], [15.833, 55], [16.666, 50], [17.5, 42.5], [18.333, 35], [19.165, 27.5], [20, 20], [20.833, 14], [21.666, 9], [22.08, 7], [22.5, 5], [23.333, 2.5], [24.165, 1], [25, 0], [100, 0], [100, 100], [0, 100]];

    const xOffset = (quartal) * 25;
    const adjustedPoints = basePoints.map(([x, y]) => {
        // Check if the point is one of the corners
        if ((x === 0 && y === 0) || (x === 100 && y === 0) || (x === 100 && y === 100) || (x === 0 && y === 100)) {
            return [x, y]; // Do not adjust corners
        }
        return [x + xOffset, y]; // Adjust other points
    });

    // Convert to `polygon()` format
    return `polygon(${adjustedPoints.map(([x, y]) => `${x}% ${y}%`).join(', ')})`;
};

const MobileFooterNavbar = ({handlePageSwitch}) => {

    const [previous_quartal, setPrevious_quartal] = useState(0);
    const [quartal, setQuartal] = useState(0);
    const [animationPhase, setAnimationPhase] = useState('idle');
    const [clipPath, setClipPath] = useState(generateClipPath(quartal));

    const animation_time = 1000

    useEffect(() => {
        const newClipPath = generateClipPath(quartal);
        setClipPath(newClipPath);
    }, [quartal]);

    const handleQuartalChange = (newQuartal, name) => {
        if (newQuartal === quartal || animationPhase === 'moving') return;

        setAnimationPhase('fading-out'); // Start fade-out
        setTimeout(() => {
            setAnimationPhase('moving');
            setQuartal(newQuartal);
            handlePageSwitch(name);
            setTimeout(() => {
                setAnimationPhase('fading-in'); // Start fade-in
                setTimeout(() => {
                    setAnimationPhase('idle'); // Reset to idle
                    setPrevious_quartal(newQuartal);
                }, 250);
            }, 500);
        }, 250);
    };

    const navbarItems = [
        { icon: Mobile_Dashboard, name: 'dashboard' },
        { icon: Mobile_Stats, name: 'graph' },
        { icon: Mobile_Signal, name: 'signal' },
        { icon: Mobile_Settings, name: 'settings' },
    ];

    return (
        <footer className='Mobile__Footer__Navbar'>
            <div
                className="Mobile__Footer__Navbar__items__active__container"
                style={{
                    left: animationPhase === 'moving'
                        ? `${(quartal * 25 - previous_quartal * 25) / 2 + previous_quartal * 25 + 12.5}%`
                        : `${quartal * 25 + 12.5}%`,
                    transform:
                        animationPhase === 'moving'
                            ? 'translate(-50%, 60px)' // Move down and slide
                            : 'translate(-50%, 0)', // Move up after completing
                    boxShadow: animationPhase === 'idle' ? '0px 3px 6px rgba(0, 0, 0, 0.5)' : '0px 4px 6px rgba(0, 0, 0, 0.0)',
                    transition: `transform ${animation_time / 2000}s ease-in-out, left ${animation_time / 2000}s ease-in-out`,
                }}
            ></div>
            <div
                className='Mobile__Footer__Navbar__items__container'
                style={{
                    clipPath,
                    transition: `clip-path ${animation_time / 1000}s ease-in-out`,
                }}
            >
                {navbarItems.map((item, index) => (
                    <div
                        key={item.name}
                        className={`Mobile__Footer__Navbar__item ${index == 0 && 'Mobile__Footer__Navbar__first__item'} ${
                            quartal === index ? "active" : ""
                        }`}
                        onClick={() => handleQuartalChange(index, item.name)}
                    >
                        <img src={item.icon} width={30} height={30} />
                    </div>
                ))}
            </div>
            <div
                className="Mobile__Footer__Navbar__items__active__icon"
                style={{
                    left: animationPhase === 'moving'
                        ? `${(quartal * 25 - previous_quartal * 25) / 2 + previous_quartal * 25 + 12.5}%`
                        : `${quartal * 25 + 12.5}%`,
                    transform:
                        animationPhase === 'moving'
                            ? 'translate(-50%, 0px)' // Move down and slide
                            : 'translate(-50%, -40px)', // Move up after completing
                    opacity: animationPhase === 'idle' ? 1 : 0, // Fade out when moving, fade in after
                    transition: `
                        transform ${animation_time / 2000}s ease-in-out, 
                        left ${animation_time / 2000}s ease-in-out,
                        opacity 0.25s ease-in-out`,
                }}
            >
                <img src={navbarItems[quartal].icon} width={30} height={30} />
            </div>
        </footer>
    )
}

export default MobileFooterNavbar;