import React from 'react';
import './homePageSectoren.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import foto from '../../../Images/shutterstock_2323423867.png';
import { useTranslation } from 'react-i18next';

const HomePageSectoren = () => {
    const { t } = useTranslation();

    return (
        <div>
            <div className="homePage__sectoren__wrapper">
                <div className="homePage__sectoren__container">
                    <div>
                        <div className="homePage__sectoren__header">
                            <i className="homePage__sectoren__icon fas fa-industry"></i>
                            <h3>{t('homePageSectoren.industrie')}</h3>
                        </div>
                        <p>{t('homePageSectoren.industrieDescription')}</p>
                        <a href="/Industrie" className="homePage__sectoren__discover">
                            {t('homePageSectoren.readMore')} <i className='fas fa-arrow-right' />
                        </a>
                    </div>
                    <div>
                        <div className="homePage__sectoren__header">
                            <i className="homePage__sectoren__icon fas fa-house"></i>
                            <h3>{t('homePageSectoren.residentieel')}</h3>
                        </div>
                        <p>{t('homePageSectoren.residentieelDescription')}</p>
                        <a href="/Residentieel" className="homePage__sectoren__discover">
                            {t('homePageSectoren.readMore')} <i className='fas fa-arrow-right' />
                        </a>
                    </div>
                    <div>
                        <div className="homePage__sectoren__header">
                            <i className="homePage__sectoren__icon fas fa-tractor"></i>
                            <h3>{t('homePageSectoren.landbouw')}</h3>
                        </div>
                        <p>{t('homePageSectoren.landbouwDescription')}</p>
                        <a href="/Landbouw" className="homePage__sectoren__discover">
                            {t('homePageSectoren.readMore')} <i className='fas fa-arrow-right' />
                        </a>
                    </div>
                    <div>
                        <div className="homePage__sectoren__header">
                            <i className="homePage__sectoren__icon fas fa-building"></i>
                            <h3>{t('homePageSectoren.horeca')}</h3>
                        </div>
                        <p>{t('homePageSectoren.horecaDescription')}</p>
                        <a href="/Horeca" className="homePage__sectoren__discover">
                            {t('homePageSectoren.readMore')} <i className='fas fa-arrow-right' />
                        </a>
                    </div>
                </div>
            </div>
            <img src={foto} alt='foto' className='homePage__foto' />
        </div>
    );
}

export default HomePageSectoren;