import { Area, AreaChart, CartesianGrid, ComposedChart, Legend, Line, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import './mobileDashboardHistoric.css'
import { useEffect, useMemo, useState } from 'react';
import { addHours, format, startOfDay } from 'date-fns';

const MobileDashboardHistoric = ({data = [], globalDate = new Date(), chargeName, dischargeName, type = 'imbalance', battcap = 40}) => {

    const hourlyTicks = useMemo(() => {
        const start = startOfDay(new Date(globalDate));
        const ticks = Array.from({length: 24}, (_, i) => addHours(start, i).getTime());
        return [...new Set(ticks)];
    }, [globalDate])
    
    const [chartData, setChartdata] = useState([])

    useEffect(() => {
        if (data) {
            setChartdata(data)
        }
    }, [data])

    const CustomTooltip = ({ payload, label, active }) => {
        if (active) {
          // You can access the data inside payload to customize the content
          const { soc, charge_imbalancesetpoint, discharge_imbalancesetpoint, charge_setpointData, discharge_setpointData } = payload[0]?.payload || {};
      
          return (
            <div className="custom-tooltip">
                <div className='signal__historic__graph__tooltip__item__container'><div style={{backgroundColor: '#55D6C2'}} className='signal__historic__graph__tooltip__item__icon'></div><div>{soc}<span style={{opacity: 0.25}}>.00 kWh</span></div></div>
                {type == 'imbalance' && <div className='signal__historic__graph__tooltip__item__container'><div style={{backgroundColor: '#65D8A0'}} className='signal__historic__graph__tooltip__item__icon'></div><div>{charge_imbalancesetpoint}<span style={{opacity: 0.25}}>.00 kWh</span></div></div>}
                {type == 'imbalance' && <div className='signal__historic__graph__tooltip__item__container'><div style={{backgroundColor: '#F88C8C'}} className='signal__historic__graph__tooltip__item__icon'></div><div>{discharge_imbalancesetpoint}<span style={{opacity: 0.25}}>.00 kWh</span></div></div>}
                {type == 'dayahead' && <div className='signal__historic__graph__tooltip__item__container'><div style={{backgroundColor: '#65D8A0'}} className='signal__historic__graph__tooltip__item__icon'></div><div>{charge_setpointData}<span style={{opacity: 0.25}}>.00 kWh</span></div></div>}
                {type == 'dayahead' && <div className='signal__historic__graph__tooltip__item__container'><div style={{backgroundColor: '#F88C8C'}} className='signal__historic__graph__tooltip__item__icon'></div><div>{discharge_setpointData}<span style={{opacity: 0.25}}>.00 kWh</span></div></div>}
            </div>
          );
        }
        return null;
    };

    return (
        <div className='Mobile__Dashboard__Signal__historic__container'>
            <div className='Mobile__Dashboard__Signal__historic__graph__legend__container'>
                <div className='historic__graph__legend__item'>
                    <div style={{backgroundColor: '#55D6C2'}} className='historic__graph__legend__item__icon'></div>
                    <div style={{opacity: 0.25}}>soc</div>
                </div>
                <div className='historic__graph__legend__item'>
                    <div style={{backgroundColor: '#65D8A0'}} className='historic__graph__legend__item__icon'></div>
                    <div style={{opacity: 0.25}}>import</div>
                </div>
                <div className='historic__graph__legend__item'>
                    <div style={{backgroundColor: '#F88C8C'}} className='historic__graph__legend__item__icon'></div>
                    <div style={{opacity: 0.25}}>export</div>
                </div>
            </div>
            <div className='Mobile__Dashboard__Signal__historic__graph__container'>
                <ResponsiveContainer width='100%' height="100%">
                    <ComposedChart data={chartData}
                        margin={{ top: 5, right: 0, left: 0, bottom: 0 }}>
                        <defs>
                            <linearGradient id="soc" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="0%" stopColor="#55D6C2" stopOpacity={0.5}/>
                            <stop offset="70%" stopColor="#55D6C2" stopOpacity={0.1}/>
                            <stop offset="100%" stopColor="#55D6C2" stopOpacity={0}/>
                            </linearGradient>
                            <linearGradient id="import" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="0%" stopColor="#65D8A0" stopOpacity={1}/>
                            <stop offset="70%" stopColor="#65D8A0" stopOpacity={0.75}/>
                            <stop offset="100%" stopColor="#65D8A0" stopOpacity={0.5}/>
                            </linearGradient>
                            <linearGradient id="export" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="0%" stopColor="#F88C8C" stopOpacity={1}/>
                            <stop offset="70%" stopColor="#F88C8C" stopOpacity={0.75}/>
                            <stop offset="100%" stopColor="#F88C8C" stopOpacity={0.5}/>
                            </linearGradient>
                        </defs>
                        <XAxis axisLine={false} tickLine={false} dataKey='timestamp' key='timestamp' type='number' domain={['dataMin', 'dataMax']} ticks={hourlyTicks} tickFormatter={(tick, index) => (index === 0 ? '' : format(new Date(tick), 'HH:mm'))} interval={5}/>
                        <YAxis hide={true} width={1}/>
                        <YAxis hide={true} yAxisId={'variableAxis'} domain={[0, battcap]}/>
                        <Tooltip content={<CustomTooltip />} />
                        <Area connectNulls={true} type="basis" dataKey="soc" stroke="#55D6C2" fillOpacity={1} fill="url(#soc)" strokeWidth={2}/>
                        <Area connectNulls={true} type="stepAfter" yAxisId={'variableAxis'} dataKey={`${chargeName}`} dot={false} stroke="#65D8A0" strokeWidth={0} fill='url(#import)'/>
                        <Area connectNulls={true} type="stepAfter" yAxisId={'variableAxis'} dataKey={`${dischargeName}`} dot={false} stroke="#F88C8C" strokeWidth={0} fill='url(#export)'/>
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}


export default MobileDashboardHistoric