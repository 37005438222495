import { useContext } from 'react';
import GoToUserDashboardIcon from '../../../../Images/moduleIcon.png'
import { UserContext } from '../../../../context/UserContext';
import { useNavigate } from 'react-router-dom';

const MobileCollapsablelist = ({superRole, collapsedRoles, toggleRoleCollapse, sortedInstallations}) => {
    const { login } = useContext(UserContext);
    const navigate = useNavigate();

    const navigateToUserDashboard = (user) => {
        if (user) {
            login(user);
            navigate("/HomePage");
        } else {
            console.log("Something went wrong. Try again later.");
        }
    };

    return (
        <div>
            {sortedInstallations && Object.keys(sortedInstallations).map(role => (
                <div style={{marginTop: '20px'}}>
                    <div className="Mobile__Installationlist__Header" onClick={() => toggleRoleCollapse(role)}>
                        <div style={{marginLeft: 20}}>{role}</div>
                        <div><span style={{borderTopColor: 'white'}} className={`triangle ${collapsedRoles[role] ? 'collapsed' : ''}`}></span></div>
                    </div>
                    <div>
                    {!collapsedRoles[role] && sortedInstallations[role].map((installation, index) => (
                        <div className="Mobile__Installationlist__userItem__container">
                            <div><img src={installation.picture} width={30} style={{ borderRadius: 10 }} /></div>
                            <div style={{fontSize: "0.8rem", maxWidth: "60%", overflow: 'scroll', marginLeft: "10px"}}>
                                <div>{installation.email}</div>
                                <div>{installation.app_metadata?.serial_Nr == "EDSS_ELO_POWERBLOCK" && (superRole.toLowerCase() == 'installer' || superRole.toLowerCase() == 'multi-user' ) ? <td>EDSS_LARRIDON_POWERBLOCK</td> : <td>{installation.app_metadata?.serial_Nr || "Unkown"}</td>}</div>
                            </div>
                            <div className='Mobile__Installationlist__userItem__button'>
                                {/* navigate to user dashboard */}
                                {installation.app_metadata?.role === 'User' && 
                                    <a 
                                        href="/HomePage"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            navigateToUserDashboard(installation);
                                        }}
                                        onContextMenu={(e) => {
                                            login(installation);
                                        }}
                                        style={{height: 25}}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                    <img src={GoToUserDashboardIcon} width={25} alt="" />
                                    </a>
                                }
                            </div>
                        </div>
                    ))}
                    </div>
                </div>
            ))}
        </div>
    )
}

export default MobileCollapsablelist