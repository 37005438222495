import React, { useState } from "react";
import "./downloadExcel.css";

const DownloadExcel = () => {
  const [startMonth, setStartMonth] = useState(new Date().getMonth() + 1);
  const [startYear, setStartYear] = useState(new Date().getFullYear());
  const [endMonth, setEndMonth] = useState(new Date().getMonth() + 1);
  const [endYear, setEndYear] = useState(new Date().getFullYear());
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const getFirstDay = (year, month) => `${year}-${String(month).padStart(2, "0")}-01`;
  const getLastDay = (year, month) => {
    const lastDay = new Date(year, month, 0).getDate();
    return `${year}-${String(month).padStart(2, "0")}-${lastDay}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const startDate = getFirstDay(startYear, startMonth);
    const endDate = getLastDay(endYear, endMonth);

    if (new Date(endDate) < new Date(startDate)) {
      setError("De einddatum moet na de startdatum liggen.");
      return;
    }

    setLoading(true);
    setError("");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/day-ahead-analysis/generate-report`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ startDate, endDate }),
        }
      );

      if (!response.ok) {
        throw new Error("Er ging iets mis bij het genereren van het Excel bestand");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `data_${startDate}_${endDate}.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="date-picker-container">
      <h2 className="form-title">Download Excel Rapport</h2>

      <p className="description">
        Selecteer een start- en eindmaand om een Excel-bestand te downloaden met de
        day-ahead prijzen per uur voor de gekozen periode.
        Hoe groter de periode, hoe langer het laden kan duren.
      </p>

      <p><strong>Wat zit er in het bestand?</strong></p>
      <ul>
        <li><strong>Tabblad 1:</strong> Uurdata (day-ahead prijzen per uur)</li>
        <li><strong>Tabblad 2:</strong> Dagdata (berekende prijzen per dag)</li>
        <li><strong>Tabblad 3:</strong> Maanddata (berekende prijzen per maand)</li>
        <li><strong>Tabblad 4:</strong> Grafieken</li>
      </ul>

      {error && <div className="alert alert-danger">{error}</div>}

      <form onSubmit={handleSubmit} className="date-picker-form">
        <div className="form-group-inline">
          <div className="form-group">
            <label>Startmaand:</label>
            <select
              value={startMonth}
              onChange={(e) => setStartMonth(parseInt(e.target.value))}
              required
              className="date-input"
            >
              {Array.from({ length: 12 }, (_, i) => (
                <option key={i + 1} value={i + 1}>
                  {new Date(0, i).toLocaleString("nl-BE", { month: "long" })}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Startjaar:</label>
            <select
              value={startYear}
              onChange={(e) => setStartYear(parseInt(e.target.value))}
              required
              className="date-input"
            >
              {Array.from({ length: 10 }, (_, i) => (
                <option key={i} value={new Date().getFullYear() - i}>
                  {new Date().getFullYear() - i}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="form-group-inline">
          <div className="form-group">
            <label>Eindmaand:</label>
            <select
              value={endMonth}
              onChange={(e) => setEndMonth(parseInt(e.target.value))}
              required
              className="date-input"
            >
              {Array.from({ length: 12 }, (_, i) => (
                <option key={i + 1} value={i + 1}>
                  {new Date(0, i).toLocaleString("nl-BE", { month: "long" })}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Eindjaar:</label>
            <select
              value={endYear}
              onChange={(e) => setEndYear(parseInt(e.target.value))}
              required
              className="date-input"
            >
              {Array.from({ length: 10 }, (_, i) => (
                <option key={i} value={new Date().getFullYear() - i}>
                  {new Date().getFullYear() - i}
                </option>
              ))}
            </select>
          </div>
        </div>

        <button type="submit" className="submit-button" disabled={loading}>
          {loading ? "Bezig met downloaden..." : "Download Excel"}
        </button>
        {loading && <p>Het bestand wordt gegenereerd, dit kan enkele ogenblikken duren...</p>}
      </form>
    </div>
  );
};

export default DownloadExcel;
