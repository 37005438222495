import { Label, ComposedChart, CartesianGrid , Legend, CartesianAxis, ResponsiveContainer, XAxis, YAxis, Bar, Tooltip } from 'recharts'
import './graphMaker.css'
import { useEffect, useState } from 'react';
import { addHours, format, startOfDay, endOfMonth, startOfMonth, eachDayOfInterval, startOfWeek, endOfWeek, eachDayOfInterval as eachDay } from 'date-fns';

const GraphMaker = ({handleRetry, showRetryMessage, data, chartObjects, handleSelectedItemRemove, date, viewMode = 'day'}) => {

    const [isPercentageNeeded, setIsPercentageNeeded] = useState(false);
    const [ticks, setTicks] = useState([]);

    const generateTicks = () => {
        if (viewMode === 'day') {
            const start = startOfDay(date);
            return Array.from({ length: 24 }, (_, i) => addHours(start, i).getTime());
        } else if (viewMode === 'week') {
            const start = startOfWeek(date, { weekStartsOn: 1 });
            const end = endOfWeek(date, { weekStartsOn: 1 });
            return eachDay({ start, end }).map(day => day.getTime());
        } else if (viewMode === 'month') {
            const start = startOfMonth(date);
            const end = endOfMonth(date);
            return eachDayOfInterval({ start, end }).map(day => day.getTime());
        }
        return [];
    };

    const formatTick = (tick) => {
        if (viewMode === 'day') {
            return format(new Date(tick), "HH:mm");
        } else if (viewMode === 'week') {
            return format(new Date(tick), "EEE");
        } else if (viewMode === 'month') {
            return format(new Date(tick), "dd");
        } else if (viewMode === 'year') {
            return format(new Date(tick), "MMM");
        }
        return "";
    };

    const getTickInterval = () => {
        if (viewMode === 'day') {
            return 1;
        } else if (viewMode === 'week') {
            return 0;
        } else if (viewMode === 'month') {
            const daysInMonth = ticks.length;
            return daysInMonth <= 15 ? 1 : 2;
        }
        return 1;
    };


    
    const getDomainPadding = () => {
        if (viewMode === 'day') {
            return ['dataMin - 1800000', 'dataMax + 1800000'];
        } else if (viewMode === 'week') {
            return ['dataMin - 43200000', 'dataMax + 43200000'];
        } else if (viewMode === 'month') {
            return ['dataMin - 43200000', 'dataMax + 43200000'];
        }
        return ['dataMin', 'dataMax'];
    };

    const formatYAxis = (value) => {
        if (viewMode === 'day') {
            return (value / 1000).toFixed(1);
        } else if (viewMode === 'week' || viewMode === 'month') {
            return value.toFixed(0);
        }
        return value;
    };

    const formatTooltipValue = (value, unit) => {
        if (unit === '%') {
            return Math.ceil(value) + unit;
        } else if (viewMode === 'day') {
            return (value / 1000).toFixed(2) + unit;
        } else {
            return value.toFixed(1) + unit;
        }
    };

    useEffect(() => {
        setTicks(generateTicks());
    }, [date, viewMode]);

    useEffect(() => {
        const needsPercentage = chartObjects.some(obj =>
            obj.object.props.yAxisId === 'Percentage'
        );
        setIsPercentageNeeded(!needsPercentage);
    }, [chartObjects]);

    const handleLegendClick = (event) => {
        handleSelectedItemRemove(event.dataKey);
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            let timeDisplay;

            if (viewMode === 'day') {
                timeDisplay = format(new Date(label), "HH:mm");
            } else if (viewMode === 'week') {
                timeDisplay = format(new Date(label), "EEE dd MMM");
            } else if (viewMode === 'month') {
                timeDisplay = format(new Date(label), "dd MMM");
            }

            const values = payload.map((entry) => (
                <div key={entry.dataKey} style={{ color: entry.color }}>
                    {entry.name}: {formatTooltipValue(entry.value, entry.unit)}
                </div>
            ));

            return (
                <div style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    padding: '10px',
                    border: '1px solid #ddd',
                    borderRadius: '10px',
                }}>
                    <div><strong>{viewMode === 'day' ? 'Time' : 'Date'}:</strong> {timeDisplay}</div>
                    {values}
                </div>
            );
        }
        return null;
    };

    if (showRetryMessage) {
        return (
            <div className='dashboard__savings__container' style={{ justifyContent: 'center', alignItems: 'center' }}>
                <div>Error loading data. Please try again later.</div>
                <button onClick={handleRetry} className='dashboard__savings__retry__button'>Retry</button>
            </div>
        );
    }


    return (
        <ResponsiveContainer style={{ fontSize: "1rem", marginTop: 20 }} width={"100%"} height="85%">
            <ComposedChart data={data} key={date.toISOString() + viewMode}>
                <XAxis interval={getTickInterval()} dataKey="timestamp" type='number' domain={getDomainPadding()} ticks={ticks} tickFormatter={formatTick} />
                <YAxis tickFormatter={formatYAxis} unit={'kWh'} width={100} />
                <YAxis yAxisId={'Percentage'} unit={'%'} orientation='right' domain={[0, 100]} hide={isPercentageNeeded} />
                <CartesianGrid style={{ opacity: 0.5 }} vertical={false} />
                <Tooltip content={<CustomTooltip />} />
                {chartObjects.map((object) => object.object)}
                <Legend wrapperStyle={{ color: '#555', fontSize: '0.9rem', fontWeight: 'bold', cursor: 'pointer' }} onClick={handleLegendClick} />
            </ComposedChart>
        </ResponsiveContainer>
    );


}

export default GraphMaker