import React from 'react';
import { useTranslation } from 'react-i18next';
import './powerblock.css';

import powerblock from '../../../../Images/powerblock.png';
import powerblockOpen from '../../../../Images/FIRN_Powerblock.png';
import datasheet from '../../../../Files/FIRN Powerblock.pdf';

const Powerblock = () => {
    const { t } = useTranslation();

    return (
        <div className="powerblockPage__container">
            <section className="powerblockPage__introduction">
                <div className="powerblockPage__header">
                    <h1>{t('powerblock.powerblockTitle')}</h1>
                    <a
                        href={datasheet}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="powerblockPage__datasheet-link"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                            <polyline points="14 2 14 8 20 8"></polyline>
                            <line x1="9" y1="15" x2="15" y2="15"></line>
                        </svg>
                        <span>{t('viewDatasheet')}</span>
                    </a>
                </div>
                <p>
                    {t('powerblock.powerblockDescription')}
                </p>
            </section>

            <section className="powerblockPage__content">
                <div className="powerblockPage__text-content">
                    <div className="powerblockPage__text">
                        <h2>{t('powerblock.whyChooseTitle')}</h2>
                        <p>
                            {t('powerblock.whyChooseDescription')}
                        </p>
                        <ul>
                            <li><strong>{t('powerblock.feature1')}:</strong> {t('powerblock.feature1Description')}</li>
                            <li><strong>{t('powerblock.feature2')}:</strong> {t('powerblock.feature2Description')}</li>
                            <li><strong>{t('powerblock.feature3')}:</strong> {t('powerblock.feature3Description')}</li>
                            <li><strong>{t('powerblock.feature4')}:</strong> {t('powerblock.feature4Description')}</li>
                        </ul>
                    </div>

                    <div className="powerblockPage__features">
                        <h2>{t('powerblock.features')}</h2>
                        <ul>
                            <li><strong>{t('powerblock.feature1')}:</strong> {t('powerblock.feature1Description')}</li>
                            <li><strong>{t('powerblock.feature2')}:</strong> {t('powerblock.feature2Description')}</li>
                            <li><strong>{t('powerblock.feature3')}:</strong> {t('powerblock.feature3Description')}</li>
                            <li><strong>{t('powerblock.feature4')}:</strong> {t('powerblock.feature4Description')}</li>
                        </ul>
                    </div>
                </div>
                <div className="powerblockPage__image-block">
                    <img src={powerblock} alt={t('powerblock.powerblockTitle')} />
                </div>
            </section>

            <section className="powerblockPage__open-image">
                <img src={powerblockOpen} alt={t('powerblock.powerblockTitle')} />
            </section>
        </div>
    );
};

export default Powerblock;
