import './mobileDashboardSettings.css';

import logoutIcon from '../../../../Images/icons8-logout-100-white.png'
import { useAuth0 } from '@auth0/auth0-react';
import { useContext } from 'react';
import { UserContext } from '../../../../context/UserContext';
import { useNavigate } from 'react-router-dom';

const MobileDashboardSettings = ({superRole}) => {

    const { logout } = useAuth0();

    const { mockLogout } = useContext(UserContext);
    const navigate = useNavigate();

    const LogoutFunction = () => {
        mockLogout()
        logout()
    }

    const returnToAdminPage = () => {
        mockLogout()
        navigate("/AdminPage")
    }

    return (
        <div className='SettingsPage__Container'>
            <header className='Dashboard__Mobile__Overview__Navbar'>
                <div>
                    <div style={{fontSize: '2rem', marginBottom: 5}}>Settings</div>
                </div>
            </header>
            <div>
                <div className='Dashboard__Mobile__Settings__Logout__Container' onMouseDown={() => LogoutFunction()}>
                    <div style={{fontSize: '1.2rem'}}>Log out</div>
                    <div>
                        <img width={35} src={logoutIcon} alt="" />
                    </div>
                </div>
                {superRole && 
                    <div className='Dashboard__Mobile__Settings__Logout__Container' style={{backgroundColor: '#1E3A5F', border: '2px solid #4A90E2', boxShadow: '0px 5px 5px 0px rgba(74, 144, 226, 0.25)'}} onMouseDown={returnToAdminPage}>
                        <div style={{fontSize: '1.2rem'}}>Return to admin dashboard</div>
                        <div>
                            <img width={35} src={logoutIcon} alt="" />
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default MobileDashboardSettings;