const register = (config) => {
    if ('serviceWorker' in navigator) {
        window.addEventListener('load', () => {
            const swUrl = `/service-worker.js`;

            navigator.serviceWorker
                .register(swUrl)
                .then((registration) => {
                    console.log('Service worker registered: ', registration);
                })
                .catch((error) => {
                    console.log('Service worker registration failed: ', error);
                });
        });
    };
};

export { register };