import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/UserContext";
import User from "../../../api/user";
import axios from "axios";
import './mobileAdminDashboard.css'
import MobileCollapsablelist from "./Mobile-InstallationList/mobileCollapsablelist";

const MobileAdminDasboard = ({username = '', role, user}) => {

    const { login } = useContext(UserContext);
    const userApi = User();

    const [superRole, setSuperRole] = useState("");
    const [installations, setInstallations] = useState([]);

    const [sortedInstallations, setSortedInstallations] = useState(null);
    const [collapsedRoles, setCollapsedRoles] = useState({});

    const fetchInstallations = async (role) => {
        let installationsresponse;
        console.log(role)
        setSuperRole(role)
        //retrieve client list from auth0 (installer & multi user)
        if (role === "Installer" || role == "Multi-User") {
            const domain = process.env.REACT_APP_AUTH0_DOMAIN;
            const token = await userApi.getManagementApiToken();

            let metaData = "";

            const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
            const metadataResponse = await axios.get(userDetailsByIdUrl, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            
            metaData = metadataResponse.data.app_metadata;
            installationsresponse = await userApi.getUsersBySerialNr(metaData.clients);

            if (metaData.serial_Nr) {
                const filteredInstallations = installationsresponse.filter(installation => {
                    if (installation.app_metadata.serial_Nr === metaData.serial_Nr) {
                        installation.app_metadata.role = "User";
                    }

                    return installation;
                });

                setInstallations(filteredInstallations);
            } else {

                setInstallations(installationsresponse);
            }
        } else if (role === "Admin" || role === "SuperAdmin" || role === "Sales") {
            try {
                const userResponse = await userApi.getAllUsersInDatabase();
                installationsresponse = userResponse;

                setInstallations(installationsresponse);
            } catch (error) {
                console.log(error);
                setInstallations([]);
            }
        }
    };

    const sortInstallationsIntoLists = () => {
        const tempSortedList = {}

        const sortedByEmail = installations.sort((a, b) => {
            return a.email.toLowerCase().localeCompare(b.email.toLowerCase());
        });

        try {
            for (let installation of sortedByEmail) {
                const role = installation.app_metadata?.role || "Other";
    
                if (!tempSortedList[role]) {
                    tempSortedList[role] = [];
                }
                tempSortedList[role].push(installation);
            }
    
            // Define a custom order for roles
            const roleOrder = ["User", "Multi-User", "Installer", "Admin", "SuperAdmin", "Sales", "Other"];
    
            // Sort the roles according to the predefined order
            const sortedRoles = Object.keys(tempSortedList).sort((a, b) => {
                return roleOrder.indexOf(a) - roleOrder.indexOf(b);
            });
    
            // Rebuild sortedInstallations with the sorted roles
            const orderedSortedInstallations = {};
            sortedRoles.forEach(role => {
                orderedSortedInstallations[role] = tempSortedList[role];
            });
    
            setSortedInstallations(orderedSortedInstallations);
    
            // Set collapsed state
            const initialCollapsedState = {};
            sortedRoles.forEach(role => {
                initialCollapsedState[role] = !(role === "User" || role === "Installer" || role === "Multi-User");
            });
            setCollapsedRoles(initialCollapsedState);
    
        } catch (e) {
            setSortedInstallations(null);
        }
    }

    useEffect(() => {
        fetchInstallations(role, null);
    }, [role]); 

    useEffect(() => {
        sortInstallationsIntoLists()
    }, [installations])

    const getGreeting = () => {
        const hour = new Date().getHours();
        if (hour < 12) {
            return "Good morning";
        } else if (hour < 18) {
            return "Good afternoon";
        } else {
            return "Good evening";
        }
    };

    const toggleRoleCollapse = (role) => {
        setCollapsedRoles(prevState => ({
            ...prevState,
            [role]: !prevState[role]
        }));
    }

    return (
        <div className="Dashboard__Mobile__Container">
            <div className='Dashboard__Mobile__Overview__Container'>
                <header className='Dashboard__Mobile__Overview__Navbar'>
                    <div>
                        <div style={{fontSize: '2rem', marginBottom: 5}}>Admin Dashboard</div>
                        <div style={{fontSize: '1.25rem'}}><span>{getGreeting()}</span> <span style={{color: "#41B1F0"}}>{username}!</span></div>
                    </div>
                </header>
                <div className="Dashboard__Mobile__InstalltionList__Container">
                    <div style={{ fontSize: '1.2rem', marginBottom: '10px'}}>Installations:</div>
                    <MobileCollapsablelist sortedInstallations={sortedInstallations} collapsedRoles={collapsedRoles} superRole={superRole} toggleRoleCollapse={toggleRoleCollapse}/>
                </div>
            </div>  
        </div>
    );
}

export default MobileAdminDasboard;