import React from 'react';
import { useTranslation } from 'react-i18next';
import './batteryTower.css';

import batteryTower from '../../../../Images/batteryStack.png';
import singleBattery from '../../../../Images/singleBattery.png';
import outdoorCabinet from '../../../../Images/outdoorCabinet.png';
import datasheet from '../../../../Files/FIRN Bat5100.pdf';
import BuitenBehuizingDatasheet from '../../../../Files/FIRN outdoor-cabinet-v3.pdf';

const BatteryTower = () => {
    const { t } = useTranslation();

    return (
        <div className="batteryPage__container">
            <section className="batteryPage__introduction">
                <div className="batteryPage__header">
                    <h1>{t('batteryTower.batteryTitle')}</h1>
                    <a
                        href={datasheet}
                        className="batteryPage__datasheet-link"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                            <polyline points="14 2 14 8 20 8"></polyline>
                            <line x1="9" y1="15" x2="15" y2="15"></line>
                        </svg>
                        <span>{t('viewDatasheet')}</span>
                    </a>
                </div>
                <div className="batteryPage__intro-content">
                    <img src={singleBattery} alt="Batterij uit de toren" className="batteryPage__single-battery" />
                    <div className="batteryPage__intro-text">
                        <p>{t('batteryTower.batteryDescription')}</p>
                    </div>
                </div>
            </section>

            <section className="batteryPage__tower">
                <div className="batteryPage__tower-content">
                    <div className="batteryPage__tower-text">
                        <h2>{t('batteryTower.towerTitle')}</h2>
                        <p>{t('batteryTower.towerDescription1')}</p>
                        <p>{t('batteryTower.towerDescription2')}</p>
                        <h3>{t('batteryTower.whyChoose')}</h3>
                        <ul>
                            <li><strong>{t('batteryTower.storage')}:</strong> {t('batteryTower.storageDescription')}</li>
                            <li><strong>{t('batteryTower.independence')}:</strong> {t('batteryTower.independenceDescription')}</li>
                            <li><strong>{t('batteryTower.advancedManagement')}:</strong> {t('batteryTower.advancedManagementDescription')}</li>
                            <li><strong>{t('batteryTower.scaling')}:</strong> {t('batteryTower.scalingDescription')}</li>
                        </ul>
                    </div>
                    <div className="batteryPage__tower-image">
                        <img src={batteryTower} alt="Batterij Toren" />
                    </div>
                </div>
            </section>

            <div className="batteryPage__installation-and-cabinet">
                <div className="batteryPage__cabinet-image">
                    <img src={outdoorCabinet} alt="Outdoor Cabinet" />
                </div>

                <section className="batteryPage__cabinet">
                    <h2>{t('batteryTower.cabinet')}</h2>
                    <div className="batteryPage__cabinet-content">
                        <p>{t('batteryTower.cabinetDescription')}</p>
                        <ul>
                            <li>{t('batteryTower.cabinetFeature1')}</li>
                            <li>{t('batteryTower.cabinetFeature2')}</li>
                            <li>{t('batteryTower.cabinetFeature3')}</li>
                        </ul>
                        <p>{t('batteryTower.cabinetConclusion')}</p>
                    </div>
                    <a
                        href={BuitenBehuizingDatasheet}
                        className="batteryPage__datasheet-link"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                            <polyline points="14 2 14 8 20 8"></polyline>
                            <line x1="9" y1="15" x2="15" y2="15"></line>
                        </svg>
                        <span>{t('viewDatasheet')}</span>
                    </a>
                </section>
            </div>
        </div>
    );
}

export default BatteryTower;