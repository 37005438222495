import { useCallback, useEffect, useRef, useState } from 'react';
import MobileDashboardSpeedometer from './Mobile-Dashboard-Speedometer/mobileDashboardSpeedometer';
import './mobileDashboardSignals.css'
import MobileDashboardHistoric from './Mobile-Dashboard-Historic/mobileDashboardHistoric';
import useImbalanceData from '../../Dashboard-Hooks/useImbalanceData';
import useSOCData from '../../Dashboard-Hooks/useSOCData';
import useInstallationDetails from '../../Dashboard-Hooks/useInstallationDetails';
import useSetpointData from '../../Dashboard-Hooks/useSetpointData';

const MobileDashboardSignals = ({serialNumber, globalDate}) => {

    const isFetchingImbalance = useRef(false);
    const isFetchingSOC = useRef(false);
    const isFetchingDayAhead = useRef(false);

    const [imbalance_value, setImbalanceValue] = useState(40);
    const [dayahead_value, setDayAheadValue] = useState(15);

    const [imbalance_currentTab, setImbalanceCurrentTab] = useState("current");
    const [dayahead_currentTab, setDayAheadCurrentTab] = useState("current");

    const imbalance_colors = ["#1FD3B8", "#00CBD9", "#00C5EA", "#06C0F8", "#36B7FF", "#72AFFE", "#94A1FE", "#D77CE1", "#FE6AAE", "#FF418F", "#D77CE1"];
    const dayahead_colors = ["#FF686B", "#FF7E70", "#FF9475", "#FFA87D", "#FFBC85", "#FFD166", "#D9E566", "#B3E673", "#8DE683", "#66E59A", "#5CE68A"];

    const [installationDetails, setInstallationDetails] = useState(null);
    const [socData, setSOCData] = useState(null);
    const [imbalanceChartData, setImbalanceChartData] = useState(null);
    const [dayaheadChartData, setDayaheadChartData] = useState(null);

    const [foundImbalanceData, setFoundImbalanceData] = useState(false)
    const [foundDayaheadData, setFoundDayaheadData] = useState(false)

    const [maxBattCap, setMaxBattCap] = useState(0);

    const [currentImbalance, setCurrentImbalance] = useState(0);
    const [currentDayahead, setCurrentDayahead] = useState(0);

    useEffect(() => {
        if (installationDetails) {
            setMaxBattCap(installationDetails.batterys.reduce((acc, battery) => acc + battery.capacity_kwh, 0));
        }
    }, [installationDetails])


    //fetch dayahead data
    useEffect(() => {
        const fetchDayAheadData = async () => {
            if (isFetchingDayAhead.current) return;
    
            if (!installationDetails || !socData) return;

            isFetchingDayAhead.current = true;

            try {
                const [{ charge_setpointData, discharge_setpointData }] = await Promise.all([
                    useSetpointData(serialNumber, globalDate, installationDetails)
                ]);
    
                if ((charge_setpointData && charge_setpointData.length > 0) || (discharge_setpointData && discharge_setpointData.length > 0)) {

                    //merge imbalance data with soc data
                    const mergedData = mergeDataArrays(
                        socData || [],
                        charge_setpointData || [],
                        discharge_setpointData || []
                    );

                    setFoundDayaheadData(true)

                    console.log(mergedData, 'dayahead confirmed')

                    if (charge_setpointData[charge_setpointData.length - 1] != 0) {
                        setCurrentImbalance(charge_setpointData[charge_setpointData.length - 1])
                    }
                    if (discharge_setpointData[discharge_setpointData.length - 1] != 0) {
                        setCurrentImbalance(discharge_setpointData[discharge_setpointData.length - 1])
                    }

                    setDayaheadChartData(mergedData.sort((a, b) => a.timestamp - b.timestamp))
                } else {
                    setFoundDayaheadData(false)
                }
            } catch (e) {
                console.error(e)
            } finally {
                isFetchingDayAhead.current = false;
            }
        }

        fetchDayAheadData();

    }, [serialNumber, globalDate, installationDetails, socData])
    

    //fetch soc data
    const fetchSOCData = useCallback(async () => {
        if (isFetchingSOC.current) return;

        isFetchingSOC.current = true;

        try {
            if (maxBattCap) {
                const [socData] = await Promise.all([
                    useSOCData(serialNumber, globalDate)
                ]);

                const transformedata = socData.map(item => ({
                    ...item,
                    soc: Math.round((item.soc * maxBattCap) / 100)
                }))

                setSOCData(transformedata)
            }
        } catch (e) {
            console.error(e)
        } finally {
            isFetchingSOC.current = false;
        }
    }, [serialNumber, globalDate, maxBattCap])

    //fetch the imbalance data
    useEffect(() => {
        const fetchImbalanceData = async() => {
            if (isFetchingImbalance.current) return;
    
            if (!installationDetails || !socData) return;
    
            isFetchingImbalance.current = true;
    
            try {
                const [{ charge_imbalanceData, discharge_imbalanceData}] = await Promise.all([
                    useImbalanceData(serialNumber, globalDate, installationDetails)
                ]);

                if ((charge_imbalanceData && charge_imbalanceData.length > 0) || (discharge_imbalanceData && discharge_imbalanceData.length > 0)) {
                    //merge imbalance data with soc data
                    const mergedData = mergeDataArrays(
                        socData || [],
                        charge_imbalanceData || [],
                        discharge_imbalanceData || []
                    );

                    setFoundImbalanceData(true)

                    if (charge_imbalanceData[charge_imbalanceData.length - 1] != 0) {
                        setCurrentImbalance(charge_imbalanceData[charge_imbalanceData.length - 1])
                    }
                    if (discharge_imbalanceData[discharge_imbalanceData.length - 1] != 0) {
                        setCurrentImbalance(discharge_imbalanceData[discharge_imbalanceData.length - 1])
                    }

                    console.log(mergedData, 'imbalance confirmed')
                    setImbalanceChartData(mergedData.sort((a, b) => a.timestamp - b.timestamp))
                } else {
                    setFoundImbalanceData(false)
                }
            } catch (e) {
                console.error(e)
            } finally {
                isFetchingImbalance.current = false;
            }
        }

        fetchImbalanceData();

    }, [serialNumber, globalDate, installationDetails, socData])

    //fetch the soc data. Then fetch imbalance and dayahead
    useEffect(() => {
        fetchSOCData();

        const interval = setInterval(() => {
            fetchSOCData();
        }, 1000 * 60 * 2); // Fetch data every 2 minutes

        return () => clearInterval(interval);
    }, [fetchSOCData]);

    useEffect(() => {

        const retrieveInstallationDetails = async() => {
            const details = await useInstallationDetails(serialNumber)
            setInstallationDetails(details)
        }

        retrieveInstallationDetails();
    }, [serialNumber]);

    const mergeDataArrays = (...dataArrays) => {
        const mergedDataMap = new Map();

        const mergeObjects = (obj1, obj2) => ({ ...obj1, ...obj2 });

        dataArrays.forEach(dataArray => {
            dataArray.forEach(item => {
                const { timestamp } = item;

                if (mergedDataMap.has(timestamp)) {
                    const existingEntry = mergedDataMap.get(timestamp);
                    mergedDataMap.set(timestamp, mergeObjects(existingEntry, item));
                } else {
                    mergedDataMap.set(timestamp, item);
                }
            });
        });

        return Array.from(mergedDataMap.values());
    }

    return (
        <div>
            <header className='Dashboard__Mobile__Overview__Navbar'>
                <div>
                    <div style={{fontSize: '2rem', marginBottom: 5}}>Signals</div>
                </div>
            </header>
            <div className='dashboard__mobile__signals__body__container'>
                {foundImbalanceData && <>
                    <div style={{fontSize: '1.25rem', marginBottom: 10}}>Imbalance</div>
                    <div className='signals__body__container__background'>
                        <div className={`signals__body__item__title ${imbalance_currentTab === 'current' && 'active'}`} style={{top: 10, left: 10}} onClick={() => setImbalanceCurrentTab('current')}>Current</div>
                        <div className={`signals__body__item__title ${imbalance_currentTab === 'historic' && 'active'}`} style={{top: 10, right: 10}} onClick={() => setImbalanceCurrentTab('historic')}>Historic</div>
                        {imbalance_currentTab === 'current' && 
                            <div>
                                <MobileDashboardSpeedometer maxbatt={maxBattCap} id={'imbalance'} color={imbalance_colors} value={currentImbalance}/>
                            </div>
                        }
                        {imbalance_currentTab == 'historic' && 
                            <MobileDashboardHistoric type='imbalance' battcap={maxBattCap} data={imbalanceChartData} globalDate={globalDate} chargeName={'charge_imbalancesetpoint'} dischargeName={'discharge_imbalancesetpoint'}/>
                        }
                    </div>
                </>}
                {foundDayaheadData && <>
                    <div style={{fontSize: '1.25rem', marginBottom: 10}}>Day-ahead</div>
                    <div className='signals__body__container__background'>
                        <div className={`signals__body__item__title ${dayahead_currentTab === 'current' && 'active'}`} style={{top: 10, left: 10}} onClick={() => setDayAheadCurrentTab('current')}>Current</div>
                        <div className={`signals__body__item__title ${dayahead_currentTab === 'historic' && 'active'}`} style={{top: 10, right: 10}} onClick={() => setDayAheadCurrentTab('historic')}>Historic</div>
                        {dayahead_currentTab === 'current' && 
                            <div>
                                <MobileDashboardSpeedometer maxbatt={maxBattCap} id={'dayahead'} color={dayahead_colors} value={currentDayahead}/>
                            </div>
                        }
                        {dayahead_currentTab == 'historic' && 
                            <MobileDashboardHistoric battcap={maxBattCap} type='dayahead' data={dayaheadChartData} globalDate={globalDate} chargeName={'charge_setpoint'} dischargeName={'discharge_setpoint'}/>
                        }
                    </div>
                </>}
            </div>
        </div>
    )

}

export default MobileDashboardSignals;

