import { Area, AreaChart, XAxis, YAxis } from 'recharts';
import './mobileGraphSliderCard.css'
import { addHours, format, startOfDay } from 'date-fns';
import { useEffect, useState } from 'react';
import loaderGIF from '../../../../../Images/firn-embleem-animation.gif'

const MobileGraphSliderCard = ({value = 'empty', unit = 'empty', name = 'empty', desc = 'empty', color = '#FFF', data = [], date = new Date(), domain = [0, 1], loading = false}) => {

    const generateHourlyTicks = () => {
        if (!data || data.length === 0) return [];

        const timestamps = data.map((item) => item.timestamp).sort((a, b) => a - b);
        const minTime = timestamps[0];
        const maxTime = timestamps[timestamps.length - 1];

        const ticks = [];
        let current = startOfDay(new Date(minTime)); // Start from the beginning of the day of the first data point

        while (current.getTime() <= maxTime) {
            ticks.push(current.getTime());
            current = addHours(current, 1); // Increment by 1 hour
        }

        return ticks;
    };
    
    useEffect(() => {
        setHourlyTicks(generateHourlyTicks())
    }, [date])    

    const [hourlyTicks, setHourlyTicks] = useState(generateHourlyTicks())

    if (loading) {
        return (
            <div className='Mobile__Graph__Slider__Card__Container'>
                <div className="dashboard__savings__container" style={{justifyContent: 'center', alignItems: 'center'}}>
                    <img style={{ margin: 'auto' }} height={150} width={150} src={loaderGIF} alt="Loading..." />
                </div>
            </div>
        )
    }
    
    return (
        <div className='Mobile__Graph__Slider__Card__Container' onClick={() => console.log("this works")}>
            <div>{value} <span style={{color: '#41B1F0'}}>{unit}</span></div>
            <div>
                <AreaChart width={175} height={80} data={data}>
                    <defs>
                        <linearGradient id={name} x1='0' x2='0' y1='0' y2='1'>
                            <stop offset='0%' stopColor={color} stopOpacity={0.9}/>
                            <stop offset='100%' stopColor={color} stopOpacity={0}/>
                        </linearGradient>
                    </defs>
                    <XAxis axisLine={{stroke: '#FFF', opacity: 0.25, strokeWidth: 2}} tick={false} tickLine={false} height={1} dataKey='timestamp' type='number' domain={['dataMin - 1800000', 'dataMax + 1800000']} ticks={hourlyTicks} tickFormatter={(tick) => format(new Date(tick), "HH:mm")}/>
                    <YAxis axisLine={{stroke: '#FFF', opacity: 0.25, strokeWidth: 2}} tick={false} tickLine={false} width={1} domain={domain}/>
                    <Area type='monotone' stroke={color} strokeWidth={2} dataKey='value' fill={`url(#${name})`} fillOpacity={1}/>
                </AreaChart>
            </div>
            <div>
                <div style={{fontSize: '1.2rem'}}>{name}</div>
                <div style={{fontSize: '0.9rem', opacity: 0.43}}>{desc}</div>
            </div>
        </div>
    )
}

export default MobileGraphSliderCard;