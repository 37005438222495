import './homePageOurSolutions.css';
import { useNavigate } from 'react-router-dom';
import { oplossingenItems } from '../OplossingenPage/OplossingsItem';
import { useTranslation } from 'react-i18next';

const selectedIds = [1, 2, 3];

const HomePageOurSolutions = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const selectedSolutions = oplossingenItems.filter(item => selectedIds.includes(item.id));

    return (
        <div className='homepage__oursolutions__container'>
            <div className='homepage__oursolutions__header__container'>
                <div className='homepage__oursolutions__header'>
                    <div className='homepage__oursolutions__header__pretitle'>
                        FIRN energy <span style={{ fontWeight: 'bold' }}>{t('homePageOurSolutions.solutions')}</span>
                    </div>
                    <div className='homepage__oursolutions__header__title'>{t('homePageOurSolutions.ourSolutions')}</div>
                    <div className='homepage__oursolutions__header__desc'>
                        {t('homePageOurSolutions.description')}
                    </div>
                </div>
            </div>
            <div className='homepage__oursolutions__content__container'>
                {selectedSolutions.map(item => (
                    <div key={item.id} className='homepage__oursolutions__content__item'>
                        <div className='homepage__oursolutions__content__header'>
                            <img
                                className='homepage__oursolutions__content__image__one'
                                src={item.imageUrl}
                                alt={item.naam[t('language')]}
                            />
                            <img
                                className='homepage__oursolutions__content__image__two'
                                src={item.imageUrl}
                                alt={item.naam[t('language')]}
                            />
                        </div>
                        <div>
                            <div className='homepage__oursolutions__item__text__header'>{item.naam[t('language')]}</div>
                            <div className='homepage__oursolutions__item__text__desc'>{item.korteBeschrijving[t('language')]}</div>
                            <div>
                                {item.voordelen.map((voordeel, index) => (
                                    <div key={index} className='homepage__oursolutions__item__advantage'>
                                        <span>+</span>
                                        <div style={{ paddingTop: '3px' }}>{voordeel[t('language')]}</div>
                                    </div>
                                ))}
                            </div>
                            <div className='homepage__oursolutions__item__btn' onClick={() => navigate(`${item.link}`)}>
                                <div style={{ paddingTop: 2 }}>{t('homePageOurSolutions.discover')}<i className='fas fa-arrow-right' /></div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default HomePageOurSolutions;
