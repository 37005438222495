import { React, useEffect, useState } from 'react';
import './mobileDashboardSpeedometer.css'

const MobileDashboardSpeedometer = ({value, color, id, maxbatt}) => {
    const [targetPercentage, setTargetPercentage] = useState(0);
    const [targetValue, setTargetValue] = useState(0);
    const [percentage, setPercentage] = useState(0);
    const [colors, setColors] = useState([]);
    const inverterCapacity = maxbatt;

    const radius = 80; // Radius of the circle
    const cx = 100; // X-coordinate of the center
    const cy = 100; // Y-coordinate of the center
    const startAngle = 135; // Start angle for the circle
    
    const getEndAngle = (percentage) => startAngle + (percentage / 100) * 270;
    const endAngle = getEndAngle(percentage);

    useEffect(() => {
        setTargetPercentage(((100 / inverterCapacity) * value).toFixed(1))
    }, [value])

    // Convert polar coordinates to Cartesian coordinates
    const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
        const angleInRadians = (angleInDegrees * Math.PI) / 180;
        return {
            x: centerX + radius * Math.cos(angleInRadians),
            y: centerY + radius * Math.sin(angleInRadians),
        };
    };

    const start = polarToCartesian(cx, cy, radius, startAngle);
    const end = polarToCartesian(cx, cy, radius, endAngle);

    // Large-arc flag: 0 if <= 180°, 1 if > 180°
    const largeArcFlag = percentage >= 66.666666 ? 1 : 0;

    // Path for the arc
    const pathData = `
        M ${start.x} ${start.y}
        A ${radius} ${radius} 0 ${largeArcFlag} 1 ${end.x} ${end.y}
    `;

    useEffect(() => {
        setColors(color);
    }, [color])

    useEffect(() => {
        let animationFrameId;
        const animate = () => {
            setPercentage((prev) => {
                if (prev < targetPercentage) return Math.min(prev + 1, targetPercentage);
                if (prev > targetPercentage) return Math.max(prev - 1, targetPercentage);
                return prev;
            });
            if (percentage !== targetPercentage) {
                animationFrameId = requestAnimationFrame(animate);
            }
        };
        animate();
        return () => cancelAnimationFrame(animationFrameId);
    }, [targetPercentage]);

    useEffect(() => {
        setTargetValue(((inverterCapacity / 100) * percentage).toFixed(1))
    }, [percentage]);

    function hexToRgb(hex) {
        const bigint = parseInt(hex.slice(1), 16);
        return {
            r: (bigint >> 16) & 255, // Extract red
            g: (bigint >> 8) & 255,  // Extract green
            b: bigint & 255          // Extract blue
        };
    }

    const getTextColor = () => {
        if (colors.length === 11) {

            // Define colors for the transitions
            const startColor = hexToRgb(colors[0]);
            const midColor = hexToRgb(colors[5]);
            const endColor = hexToRgb(colors[10]);
        
            // Function to interpolate between two colors
            const interpolateColor = (start, end, factor) => {
                const r = Math.round(start.r + (end.r - start.r) * factor);
                const g = Math.round(start.g + (end.g - start.g) * factor);
                const b = Math.round(start.b + (end.b - start.b) * factor);
                return `rgb(${r}, ${g}, ${b})`;
            };
        
            // Interpolating based on the percentage
            let factor = 0;
            if (percentage <= 50) {
                // Interpolate between startColor (cyan) and midColor (light blue)
                factor = percentage / 50;
                return interpolateColor(startColor, midColor, factor);
            } else {
                // Interpolate between midColor (light blue) and endColor (pink)
                factor = (percentage - 50) / 50;
                return interpolateColor(midColor, endColor, factor);
            }

        }
    };

    const TickMarks = () => {
        const tickAngles = Array.from({ length: 51 }, (_, i) => i * 5.4 - 135); // Generate angles from -135 to 135
        const longerTickAngles = [-135, -108, -81, -54, -27, 0, 27, 54, 81, 108, 135]; // Angles for longer ticks
      
        // Helper function to check if an angle is a longer tick
        const findLongerTickIndex = (angle) =>
          longerTickAngles.findIndex((longAngle) => Math.abs(longAngle - angle) < 0.01);
      
        // Determine the number of longer ticks to color based on the percentage
        const totalLongerTicks = longerTickAngles.length;
        const longerTicksToColor = Math.floor((percentage / 10) + 1);
      
        return (
          <g transform="translate(100,100)">
            {/* Generate tick marks dynamically */}
            <g stroke="#646464" strokeWidth="2">
              {tickAngles.map((angle) => {
                const longTickIndex = findLongerTickIndex(angle);
                const isColored = longTickIndex !== -1 && longTickIndex < longerTicksToColor;
      
                // Assign color to longer ticks or default gray for others
                const color = isColored
                  ? colors[longTickIndex % colors.length]
                  : "#646464";
      
                return (
                  <line
                    key={angle}
                    x1="0"
                    y1={longTickIndex !== -1 ? "-51.5" : "-57"}
                    x2="0"
                    y2="-61.5"
                    transform={`rotate(${angle})`}
                    stroke={color}
                    strokeWidth={longTickIndex !== -1 ? 3 : 2}
                    strokeLinecap="round"
                  />
                );
              })}
            </g>
          </g>
        );
    };

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 200 200"
            width="225"
            height="225"
            >
            <defs>
                <linearGradient id={`${id}Gradient`} gradientUnits="userSpaceOnUse">
                    {colors.map((color, index) => (
                        <stop
                            key={index}
                            offset={`${(index / (colors.length - 1)) * 100}%`}
                            stopColor={color}
                        />
                    ))}
                </linearGradient>
                <filter id="glow" x="-100%" y="-100%" width="400%" height="400%">
                    <feGaussianBlur in="SourceGraphic" stdDeviation={percentage / 10} result="blur" />
                    <feMerge>
                        <feMergeNode in="blur" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
            </defs>

            {/* <!-- Background Arc --> */}
            <path
                d="M20,100 A80,80 0 1,1 100,180"
                fill="none"
                stroke="#2C2A32"
                strokeWidth="15"
                strokeLinecap="round"
                transform="rotate(-45 100 100)"
            />

            {/* <!-- Progress Arc --> */}
            <path
                d={pathData}
                fill="none"
                stroke={`url(#${id + 'Gradient'})`}
                strokeWidth="9"
                strokeLinecap="round"
                style={{ transition: 'stroke-dasharray 0.5s ease-out' }}
            />

            {/* <!-- Tick Marks --> */}
            {TickMarks()}

            {/* <!-- Circular Indicator Glow --> */}
            {/* End Indicator Circle */}
            <circle
                cx={end.x}
                cy={end.y}
                r="7"
                fill={getTextColor()}
                strokeWidth="1"
                stroke="#000"
                strokeOpacity={0.5}
                filter="url(#glow)"
            />

            {/* Center Text */}
            <text
                x="100"
                y="105"
                textAnchor="middle"
                fontSize="35"
                fill={getTextColor()}
                fontFamily="Arial, sans-serif"
            >
                {targetValue}
            </text>
            <text
                x="100"
                y="130"
                textAnchor="middle"
                fontSize="20"
                fill={'#fff'}
                opacity={0.25}
                fontFamily="Arial, sans-serif"
            >
                kW
            </text>
        </svg>
    )

}

export default MobileDashboardSpeedometer;