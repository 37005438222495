import { useContext, useEffect, useState } from 'react';
import './mobileDashboard.css'

import MobileDashboardOverview from './Mobile-Dashboard-Overview/mobileDashboardOverview';
import MobileFooterNavbar from './Mobile-Footer-Navbar/mobileFooterNavbar';
import MobileDashboardSignals from './Mobile-Dashboard-Signals/mobileDashboardSignals';
import { UserContext } from '../../../context/UserContext';
import MobileDashboardGraphs from './Mobile-Dashboard-Graphs/mobileDashboardGraphs';
import MobileDashboardSettings from './Mobile-Dashboard-Settings/mobileDashboardSettings';

const MobileDasboard = ({superRole}) => {

    const [currentPage, setCurrentPage] = useState('dashboard');
    
    const { userMock } = useContext(UserContext);
    const [serialNr, setSerialNr] = useState('');

    useEffect(() => {
        if (userMock?.app_metadata?.serial_Nr) {
            setSerialNr(userMock.app_metadata.serial_Nr)
        }
    }, [userMock])

    const Pages = {
        'dashboard': {element: <MobileDashboardOverview username={userMock.nickname} serial_number={serialNr}/>},
        'graph': {element: <MobileDashboardGraphs />},
        'signal': {element: <MobileDashboardSignals serialNumber={serialNr} globalDate={new Date()}/>},
        'settings': {element: <MobileDashboardSettings superRole={superRole}/>},
    };

    const handlePageSwitch = (newPage) => {
        setCurrentPage(newPage)
    }

    if (!serialNr || serialNr == '') {
        return (
            <div>error</div>
        )
    }

    return (
        <div className="Dashboard__Mobile__Container">
            {Pages[currentPage].element}
            <MobileFooterNavbar handlePageSwitch={handlePageSwitch}/>
        </div>
    )
}

export default MobileDasboard;