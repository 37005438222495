import Settings from '../../../api/settings';

const useInstallationDetails = async(serialNumber) => {

    const settingsApi = Settings();

    try {

        const { power_array } = await settingsApi.getClientDetails(serialNumber);

        const batterys = [];
        const inverters = [];

        Object.values(power_array).forEach(({ battery, inverter }) => {

            if (battery) {
                batterys.push(battery);
                inverters.push(inverter);
            } 
        });

        const installationDetails = { batterys, inverters };
        return installationDetails

    } catch (e) {
        console.error(e)
    }
}

export default useInstallationDetails;