import React from 'react';
import './oplossingenPage.css';
import { oplossingenItems } from './OplossingsItem';
import { useTranslation } from 'react-i18next';

const OplossingenPage = () => {
    const { t } = useTranslation();

    return (
        <div className="oplossingenPage__body">
            <div className="oplossingenPage__grid">
                {oplossingenItems.map(item => (
                    <a href={`${item.link}`} key={item.id} className="oplossingenPage__link">
                        <div className="oplossingenPage__item">
                            <div className="oplossingenPage__image-container">
                                <img src={item.imageUrl} alt={item.naam[t('language')]} />
                            </div>
                            <div className="oplossingenPage__details">
                                <p className="oplossingenPage__title">{item.naam[t('language')]}</p>
                                <p className="oplossingenPage__text">{item.korteBeschrijving[t('language')]}</p>
                                <div className="oplossingenPage__advantages">
                                    {item.voordelen.map((voordeel, index) => (
                                        <div key={index}>
                                            <span>+</span> {voordeel[t('language')]}
                                        </div>
                                    ))}
                                </div>
                                {item.datasheet && (
                                    <a
                                        href={item.datasheet}
                                        className="oplossingenPage__datasheet"
                                        onClick={(e) => e.stopPropagation()}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <div className="oplossingenPage__datasheet-link">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                                <polyline points="14 2 14 8 20 8"></polyline>
                                                <line x1="9" y1="15" x2="15" y2="15"></line>
                                            </svg>
                                            <span>{t('viewDatasheet')}</span>
                                        </div>
                                    </a>
                                )}
                            </div>
                        </div>
                    </a>
                ))}
            </div>
        </div>
    );
}

export default OplossingenPage;